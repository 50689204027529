import { NgModule } from '@angular/core'

import { DateFromStringPipe } from '@app-pipes/date/date-from-string.pipe'

@NgModule({
    declarations: [
        DateFromStringPipe,
    ],
    exports: [
        DateFromStringPipe,
    ],
    providers: [
        DateFromStringPipe,
    ],
})
export class DatePipesModule {
}
