import { Pipe, PipeTransform } from '@angular/core'
import runes from 'runes'

@Pipe({
    name: 'truncate',
    pure: true,
})
export class TruncatePipe implements PipeTransform {

    public transform(input: string, start: number, width?: number): string {
        return input ? runes.substr(input, start, width) : ''
    }

}
