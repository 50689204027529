import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router'
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Platform } from '@ionic/angular'
import { AndroidInsets } from 'capacitor-plugin-android-insets'
import { filter } from 'rxjs'

import { StatusBarServiceConfig } from '@app-interfaces'

@Injectable()
export class StatusBarStyleService {

    private static config: StatusBarServiceConfig = {
        ignoredRoutes: [],
    }
    public statusBarStyle?: Style

    constructor(
        private readonly platform: Platform,
        private readonly router: Router,
    ) {
    }

    public static withConfig(config: StatusBarServiceConfig): typeof StatusBarStyleService {
        StatusBarStyleService.config = { ...StatusBarStyleService.config, ...config }

        return StatusBarStyleService
    }

    public async initialize() {
        if (! this.platform.is('hybrid')) {
            return
        }

        // Change style based on route change event
        this.router
            .events
            .pipe(filter((e) => e instanceof ActivationEnd && !! e.snapshot?.component))
            .subscribe((e: ActivationEnd | any) => {
                this.handleRouteChange(e.snapshot)
            })

        // Android notch
        if (this.platform.is('android')) {
            const { value: safeAreaTop = 28 } = await AndroidInsets.top()
            //const { value: safeAreaBottom = 0 } = await AndroidInsets.bottom()
            const safeAreaBottom = 0
            document.documentElement.style.setProperty('--ion-safe-area-top', `${safeAreaTop}px`)
            document.documentElement.style.setProperty('--ion-safe-area-bottom', `${safeAreaBottom}px`)
        }

        await StatusBar.setOverlaysWebView({ overlay: true })
        window.setTimeout(() => StatusBar.setOverlaysWebView({ overlay: true }), 500)
    }

    public async setStatusBarStyle(style: Style): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        if (style && style !== this.statusBarStyle) {
            this.statusBarStyle = style
            await StatusBar.setStyle({ style })

            if (this.platform.is('ios')) {
                await Keyboard.setStyle({ style: style === Style.Dark ? KeyboardStyle.Dark : KeyboardStyle.Light })
            }
        }
    }

    private async handleRouteChange(snapshot: ActivatedRouteSnapshot): Promise<void> {
        if (StatusBarStyleService.config.ignoredRoutes?.includes(snapshot.url.toString())) {
            return
        }

        await this.setStatusBarStyle(snapshot.data.statusBarStyle || StatusBarStyleService.config.defaultStatusBarStyle)
    }

}
