import { Component, Input, NgZone } from '@angular/core'

import { Contest, ContestFish, Team } from '@app-graphql'

@Component({
    selector: 'app-contest-team-fish',
    templateUrl: './contest-team-fish.component.html',
    styleUrls: ['./contest-team-fish.component.scss'],
})
export class ContestTeamFishComponent {

    @Input()
    public contest: Partial<Contest> | null = null

    @Input()
    public team: Partial<Team> | null = null

    @Input()
    public isMyTeam = false

    public currentContestFish: Partial<ContestFish> | null = null

    constructor(
        private readonly zone: NgZone,
    ) {
    }

    public showFishData(contestFish: Partial<ContestFish>): void {
        this.zone.run(() => {
            this.currentContestFish = contestFish
        })
    }

}
