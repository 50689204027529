import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { AuthGuard } from '@app-guards'
import { ContestLeaderboardPage } from '@app-pages'

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@app-pages/home/home-page.module').then((m) => m.HomePageModule),
    },
    {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/account/account-page.module').then((m) => m.AccountPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@app-pages/auth/auth-page.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'contest/:contestId',
        loadChildren: () => import('@app-pages/contest/contest-page.module')
            .then((m) => m.ContestPageModule),
    },
    {
        path: 'leaderboard',
        component: ContestLeaderboardPage,
    },
    {
        path: 'standalone-leaderboard',
        component: ContestLeaderboardPage,
    },
    {
        path: 'general',
        loadChildren: () => import('@app-pages/general/general-page.module').then((m) => m.GeneralPageModule),
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
