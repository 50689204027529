import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher, IonSearchbar, MenuController } from '@ionic/angular'
import { IonRefresherCustomEvent, IonSearchbarCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { Leaderboard, LeaderboardEntry, Team, User } from '@app-graphql'
import { CacheTtl, FilterHelperService, GeneralService, LeaderboardService, UserService } from '@app-services'

@Component({
    selector: 'app-contest-leaderboard-page',
    templateUrl: './contest-leaderboard.page.html',
    styleUrls: ['./contest-leaderboard.page.scss'],
})
export class ContestLeaderboardPage implements OnDestroy {

    public isStandalone = false
    public contestId: string | null = null
    public user: Partial<User> | null = null
    public leaderboard: Partial<Leaderboard> | null = null

    public searchResults: string[] | null = null
    public myTeamIds: string[] | null = null

    public loading = false

    private leaderboard$: Subscription
    private user$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly filterHelperService: FilterHelperService,
        private readonly generalService: GeneralService,
        private readonly leaderboardService: LeaderboardService,
        private readonly menuController: MenuController,
        private readonly userService: UserService,
    ) {
        this.leaderboard$ = this.leaderboardService.leaderboard$
            .subscribe((leaderboard: Partial<Leaderboard> | null) => {
                this.leaderboard = leaderboard
            })

        this.user$ = this.userService.user$.subscribe((user: Partial<User> | null) => {
            this.user = user
            this.myTeamIds = user?.teams?.map((team: Partial<Team>) => team.id) || null
        })

        this.activatedRoute.url.subscribe(async (url) => {
            this.isStandalone = url[0]?.path === 'standalone-leaderboard'
            if (this.isStandalone) {
                const mainMenu = (await this.menuController.getMenus())?.[0]
                mainMenu.disabled = true
            }
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
            } else {
                this.contestId = await this.getActiveContestId()
            }
            if (this.contestId) {
                await this.loadData()
            }
        })
    }

    public ngOnDestroy(): void {
        this.leaderboard$?.unsubscribe()
        this.user$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadData(true)
        await e?.detail?.complete()
    }

    public async loadData(clearCache: boolean = false): Promise<void> {
        if (! this.contestId) {
            return
        }

        this.loading = true

        await Promise.all([
            this.userService.getUser({ clearCache }),
            this.leaderboardService.getLeaderboardByContestId(
                this.contestId,
                { clearCache, cacheTtl: CacheTtl.Minute * 5 },
            ),
        ])

        this.loading = false
    }

    public filterList(e?: IonSearchbarCustomEvent<IonSearchbar> | Event): void {
        const leaderboardEntries = this.leaderboard?.leaderboardEntries
        if (! leaderboardEntries?.length) {
            return
        }

        this.searchResults = this.filterHelperService.filterBySearchTerms(
            leaderboardEntries.map((leaderboardEntry: Partial<LeaderboardEntry>) => {
                const teamMemberNames = leaderboardEntry.team.users
                    ?.map((user: Partial<User>) => `${user.firstName} ${user.lastName}`)

                return `${leaderboardEntry.team.name} ${teamMemberNames.join(', ')}`
            }),
            (e as IonSearchbarCustomEvent<IonSearchbar>)?.detail?.value ?? '',
        )
    }

    private async getActiveContestId(): Promise<string | null> {
        const generalData = await this.generalService.getGeneralData()
        return generalData?.activeContest?.id || null
    }

}
