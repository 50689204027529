<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>News</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="loadNews($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (news) {
        <div class="tablet-max-600">
            <ion-list lines="inset">
                @for (newsItem of news; track newsItem) {
                    <ion-item [button]="true"
                              [detail]="true"
                              [routerLink]="'/contest/' + contestId + '/news/' + newsItem.id"
                    >
                        @if (newsItem.image) {
                            <ion-thumbnail slot="start">
                                <img [src]="newsItem.image" alt="" />
                            </ion-thumbnail>
                        }
                        <ion-label class="ion-text-wrap">{{ newsItem.title }}</ion-label>
                        <ion-note slot="end">
                            {{ newsItem.createdAt | dateFromString | dfnsFormatDistanceToNowStrictPure : { addSuffix: true } }}
                        </ion-note>
                    </ion-item>
                }
            </ion-list>
        </div>

        @if (! news.length) {
            <app-empty icon="newspaper-outline" class="ion-h-100">
                No news articles available
            </app-empty>
        }
    }

</ion-content>
