import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { BehaviorSubject, lastValueFrom, map } from 'rxjs'

import { GeneralData, GeneralDataQuery, GeneralDataQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class GeneralService {

    public generalData$ = new BehaviorSubject<Partial<GeneralData> | null>(null)

    private generalData: Partial<GeneralData> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly generalDataQueryService: GeneralDataQueryService,
    ) {
    }

    public async getGeneralData(cacheOptions?: CacheOptions): Promise<Partial<GeneralData>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'general')
        const generalData$ = this.generalDataQueryService.fetch(undefined, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<GeneralDataQuery>) => {
                this.generalData = result.data.generalData as Partial<GeneralData>
                this.generalData$.next(this.generalData)

                return this.generalData
            }),
        )

        try {
            return await lastValueFrom(generalData$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
