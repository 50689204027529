import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { News } from '@app-graphql'
import { NewsService } from '@app-services'

@Component({
    selector: 'app-contest-news-detail-page',
    templateUrl: './contest-news-detail.page.html',
    styleUrls: ['./contest-news-detail.page.scss'],
})
export class ContestNewsDetailPage implements OnDestroy {

    public contestId: string | null = null
    public newsItemId: string | null = null
    public newsItem: Partial<News> | null = null
    public loading = false

    private newsItem$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly newsService: NewsService,
    ) {
        this.newsItem$ = this.newsService.newsItem$.subscribe((newsItem: Partial<News> | null) => {
            this.newsItem = newsItem
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
            }
            if (params.newsItemId) {
                this.newsItemId = params.newsItemId
                await this.loadNewsItem()
            }
        })
    }

    public ngOnDestroy(): void {
        this.newsItem$?.unsubscribe()
    }

    public async loadNewsItem(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        this.loading = true

        if (this.newsItemId) {
            await this.newsService.getNewsItemById(this.newsItemId, { clearCache: !! e })
        }

        await e?.detail?.complete()
        this.loading = false
    }

}
