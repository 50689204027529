import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'

@Injectable({
    providedIn: 'root',
})
export class FileHelperService {

    public dataUrlToBlob(dataUrl: string): Blob {
        const byteString = atob(dataUrl?.split(',')?.[1] || '')
        const mimeType = dataUrl?.split(',')?.[0]?.split(':')?.[1]?.split(';')?.[0]
        const buffer = new ArrayBuffer(byteString.length)

        let ia = new Uint8Array(buffer)
        ia.set(new Uint8Array(byteString.length).map((_, i) => byteString.charCodeAt(i)))

        return new Blob([buffer], { type: mimeType })
    }

    public blobToDataUrl(blob: Blob): Promise<string> {
        let reader: FileReader = new FileReader()

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string)
            }
            reader.onerror = () => {
                reject('Error reading file.')
            }
            reader.readAsDataURL(blob)
        })
    }

    public async writeBlobToFile(blob: Blob, path: string): Promise<string> {
        const dataUri = await this.blobToDataUrl(blob)
        const data = dataUri.split(',')[1]

        const file = await Filesystem.writeFile({
            data,
            path,
            directory: Directory.Data,
            recursive: true,
        })

        return Capacitor.convertFileSrc(file.uri)
    }

}
