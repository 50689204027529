import { NgModule } from '@angular/core'

import { BackButtonService, ExternalLinkService } from '@app-services'

@NgModule({
    providers: [
        BackButtonService,
        ExternalLinkService,
    ],
})
export class NavigationServiceModule {
}
