@if (team) {
    <ion-grid class="team-information">
        <ion-row>
            <ion-col class="ion-align-self-center ion-padding">
                <div class="team-information__name ion-font-size-4">{{ team.name }}</div>
                <div class="team-information__country ion-font-size-2">{{ team.country }}</div>
            </ion-col>
            <ion-col class="ion-align-self-center ion-padding">
                @for (teamUser of team.users; track teamUser) {
                    <div>
                        {{ teamUser!.firstName }}
                        {{ teamUser!.lastName }}
                    </div>
                }
            </ion-col>
        </ion-row>
    </ion-grid>
} @else {
    <ng-content select="[slot=empty]" />
}
