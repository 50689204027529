import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { from, Observable, of, switchMap, tap } from 'rxjs'

import { AuthService } from '@app-services/api'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private static excludedOperationNames: string[]

    constructor(
        private readonly authService: AuthService,
    ) {
        //
    }

    public static withExcludedOperationNames(excludedOperationNames: string[]): typeof AuthInterceptor {
        AuthInterceptor.excludedOperationNames = excludedOperationNames

        return AuthInterceptor
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if we should ignore this request
        const operationName = request.body?.operationName
        if (
            ! request.url.startsWith('http')
            || AuthInterceptor.excludedOperationNames.includes(operationName)
        ) {
            return next.handle(request)
        }

        // Load persisted token if it hasn't been loaded yet
        const auth$: any = this.authService.getToken() ? of(null) : from(this.authService.initialize())

        return auth$.pipe(switchMap(() => this.handleRequest(request, next))) as Observable<HttpEvent<any>>
    }

    private handleRequest(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any> | Observable<never>> {

        let interceptedRequest = request

        // Add access token to request if available
        const token = this.authService.getToken()
        if (token) {
            interceptedRequest = this.setTokenHeader(request, token)
        } else {
            return next.handle(request)
        }

        return next.handle(interceptedRequest).pipe(
            tap(async (response: any | object) => {
                if (
                    response?.body?.errors?.length
                    && response?.body?.errors[0]?.message?.startsWith('Unauthenticated')
                ) {
                    await this.authService.logout()

                    return next.handle(request)
                }
            }),
        )
    }

    private setTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
    }

}
