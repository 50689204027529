<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>Messages</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon name="refresh-outline" slot="icon-only" />
            </ion-button>
        </ion-buttons>

    </ion-toolbar>

</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    <div class="ion-flex ion-h-100 tablet-max-600">

        <app-push-notification-reminder [enableDismissOption]="false" />

        <ion-list lines="inset">
            @for (message of messages; track message) {
                <ion-item [button]="false"
                          [detail]="true"
                          [routerLink]="'/general/messages/' + message.id"
                >
                    <ion-label class="ion-text-wrap">{{ message?.title }}</ion-label>
                    <ion-note slot="end">
                        {{ message.createdAt | dateFromString | dfnsFormatDistanceToNowStrictPure : { addSuffix: true } }}
                    </ion-note>
                </ion-item>
            }
        </ion-list>

        <ion-infinite-scroll (ionInfinite)="loadMore($any($event))" [disabled]="hasReachedEnd">
            <ion-infinite-scroll-content loadingText="Loading more..." class="ion-margin-vertical" />
        </ion-infinite-scroll>

        @if (messages && ! messages.length) {
            <app-empty icon="checkmark-circle-outline" class="ion-h-100">
                You're all caught up!
            </app-empty>
        }

    </div>

</ion-content>
