import { Component, Input } from '@angular/core'

import { Team } from '@app-graphql'

@Component({
    selector: 'app-contest-team-information',
    templateUrl: './contest-team-information.component.html',
    styleUrls: ['./contest-team-information.component.scss'],
})
export class ContestTeamInformationComponent {

    @Input()
    public team: Partial<Team> | null = null

}
