import { Component, Input, OnInit } from '@angular/core'
import { AlertController } from '@ionic/angular'

import { PushService, StorageService } from '@app-services'

@Component({
    selector: 'app-push-notification-reminder',
    templateUrl: './push-notification-reminder.component.html',
    styleUrls: ['./push-notification-reminder.component.scss'],
})
export class PushNotificationReminderComponent implements OnInit {

    @Input()
    public enableDismissOption: boolean = true

    public hasPermission: boolean | null = null
    public messageWasDismissed: boolean | null = null

    constructor(
        private readonly alertController: AlertController,
        private readonly pushService: PushService,
        private readonly storageService: StorageService,
    ) {
        this.pushService.hasPermission$.subscribe((hasPermission: boolean | null) => {
            this.hasPermission = hasPermission
        })
    }

    public async ngOnInit(): Promise<void> {
        this.messageWasDismissed = !! (await this.storageService.get('push-notification-reminder-dismissed'))
    }

    public async enableNotifications(): Promise<void> {
        await this.pushService.requestPermission()
    }

    public async dismiss(): Promise<void> {
        this.messageWasDismissed = true
        await this.storageService.set('push-notification-reminder-dismissed', '1')

        const message = await this.alertController.create({
            header: 'Push Notifications',
            message: 'You can always enable push notifications from the \'Notifications\' page, found in the menu.',
            buttons: ['Ok'],
        })

        await message.present()
    }
}
