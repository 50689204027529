<ng-template #menu>
    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  [routerLink]="'/contest/' + contest.id + '/leaderboard'"
                  class="ion-color ion-color-primary"
                  routerDirection="root"
        >
            <ion-icon name="podium-outline" slot="start" />
            <ion-label>Leaderboard</ion-label>
        </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  [routerLink]="'/contest/' + contest.id + '/top-catches'"
                  class="ion-color ion-color-primary"
                  routerDirection="root"
        >
            <ion-icon name="fish-outline" slot="start" />
            <ion-label>Top catches</ion-label>
        </ion-item>
    </ion-menu-toggle>

    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  [routerLink]="'/contest/' + contest.id + '/live-stream'"
                  class="ion-color ion-color-primary"
                  routerDirection="root"
        >
            <ion-icon name="videocam-outline" slot="start" />
            <ion-label>Live stream</ion-label>
        </ion-item>
    </ion-menu-toggle>

    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  [routerLink]="'/contest/' + contest.id + '/news'"
                  class="ion-color ion-color-primary"
                  routerDirection="root"
        >
            <ion-icon name="newspaper-outline" slot="start" />
            <ion-label>News</ion-label>
        </ion-item>
    </ion-menu-toggle>

    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  [routerLink]="'/contest/' + contest.id + '/sponsors'"
                  class="ion-color ion-color-primary"
                  routerDirection="root"
        >
            <ion-icon name="star-outline" slot="start" />
            <ion-label>Sponsors</ion-label>
        </ion-item>
    </ion-menu-toggle>
</ng-template>

@if (contest?.id) {
    @if (mode === 'accordion') {
        <ion-accordion value="contest">
            <ion-item slot="header"
                      class="ion-color ion-color-primary"
            >
                <ion-icon slot="start" name="ribbon-outline" />
                <ion-label>Contest</ion-label>
            </ion-item>
            <div slot="content">
                <ng-container *ngTemplateOutlet="menu" />
            </div>
        </ion-accordion>
    } @else {
        <ng-container *ngTemplateOutlet="menu" />
    }
}
