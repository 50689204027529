import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'urlFromBlob',
})
export class UrlFromBlobPipe implements PipeTransform {

    public transform(value: Blob): string {
        return URL.createObjectURL(value)
    }

}
