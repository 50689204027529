<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>Sponsors</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="loadSponsors($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (sponsors) {
        <div class="tablet-max-600">
            <ion-list lines="inset">
                @for (sponsor of sponsors; track sponsor) {
                    <ion-item [button]="true"
                              [detail]="true"
                              [routerLink]="'/contest/' + contestId + '/sponsors/' + sponsor.id"
                    >
                        @if (sponsor.logo) {
                            <ion-thumbnail slot="start">
                                <img [src]="sponsor.logo" alt="" />
                            </ion-thumbnail>
                        }
                        <ion-label class="ion-text-wrap">{{ sponsor.name }}</ion-label>
                    </ion-item>
                }
            </ion-list>
        </div>

        @if (! sponsors.length) {
            <app-empty icon="star-outline" class="ion-h-100">
                No sponsors available
            </app-empty>
        }
    }

</ion-content>
