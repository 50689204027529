<ion-content [fullscreen]="true">

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    <div class="home-content">
        <div class="home-content__header">
            <ion-menu-button class="ion-color ion-color-light" />
            <app-push-notification-reminder />
        </div>

        <div class="home-content__main">

            @if (generalData?.activeContest && user?.id) {
                <app-add-fish-modal #addFishModal [breakpoints]="[0, 0.6, 1]" [initialBreakpoint]="0.6" />
                <ion-button class="ion-margin-bottom-5"
                            (click)="addFishModal.present()"
                >
                    Take photo
                    <ion-icon slot="end" name="camera-outline" />
                </ion-button>
            }

            <img alt=""
                 class="ion-pointer-events-none"
                 height="115"
                 src="/assets/images/logo.png"
            />

            @if (generalData?.activeContest) {
                <ion-button class="ion-margin-top-5"
                            [routerLink]="'/contest/' + generalData.activeContest.id + '/leaderboard'"
                >
                    View leaderboard
                    <ion-icon slot="end" name="arrow-forward-outline" />
                </ion-button>

                <ion-button class="ion-margin-top-2"
                            routerLink="/account/my-team"
                >
                    @if (user?.id) {
                        My team
                    } @else {
                        Log in
                    }
                    <ion-icon slot="end" name="arrow-forward-outline" />
                </ion-button>
            } @else if (generalData) {
                <app-empty>
                    <p>There is no active contest at this time</p>
                </app-empty>

            } @else {
                <ion-spinner class="ion-margin-top-5 ion-color ion-color-light" />
            }

        </div>
    </div>

    <app-video-background />

</ion-content>
