<video (playing)="ready = true"
       [autoplay]="autoplay"
       [class.ready]="ready"
       loop
       muted
       playsinline
       preload="auto"
>
    <source [src]="src" />
</video>
