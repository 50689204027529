import { Injectable } from '@angular/core'
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera'
import { AlertController, Platform } from '@ionic/angular'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import { format } from 'date-fns'
import { dump, GPSHelper, insert, TagValues } from 'piexif-ts'

import { PhotoExifData } from '@app-interfaces'

@Injectable()
export class PhotoService {

    constructor(
        private readonly alertController: AlertController,
        private readonly platform: Platform,
    ) {
    }

    public async takePhoto(): Promise<Photo> {
        if (this.platform.is('hybrid')) {
            const { camera } = await Camera.requestPermissions()

            if (camera === 'denied') {
                await this.showCameraPermissionMessage()
                return null
            }
        }

        return Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl,
            saveToGallery: true,
            width: 2048,
            height: 2048,
            presentationStyle: 'popover',
        })
    }

    public addExifDataToPhoto(exifData: PhotoExifData): Photo {
        const { photo, user } = exifData
        const { coords } = exifData.position!
        const { uniqueId } = exifData
        const timestamp = format(new Date(), 'yyyy:MM:dd hh:mm:ss')

        const team = user?.teams?.find((t) => t?.isActive)

        const GPS = {
            [TagValues.GPSIFD.GPSVersionID]: [2, 3, 0, 0],
            [TagValues.GPSIFD.GPSDateStamp]: format(exifData.position.timestamp, 'yyyy:MM:dd hh:mm:ss'),
            [TagValues.GPSIFD.GPSLatitudeRef]: coords.latitude < 0 ? 'S' : 'N',
            [TagValues.GPSIFD.GPSLatitude]: GPSHelper.degToDmsRational(coords.latitude),
            [TagValues.GPSIFD.GPSLongitudeRef]: coords.longitude < 0 ? 'W' : 'E',
            [TagValues.GPSIFD.GPSLongitude]: GPSHelper.degToDmsRational(coords.longitude),
        }

        const zerothIfd = {
            [TagValues.ImageIFD.Make]: user?.id || '(unknown)',
            [TagValues.ImageIFD.Model]: team?.name || '(unknown)',
            [TagValues.ImageIFD.Software]: 'Predatortour App',
        }

        const Exif = {
            [TagValues.ExifIFD.CameraOwnerName]: user?.id ? `${user?.firstName} ${user?.lastName}` : '(unknown)',
            [TagValues.ExifIFD.DateTimeOriginal]: timestamp,
            [TagValues.ExifIFD.ImageUniqueID]: uniqueId,
        }

        const exifBytes = dump({ '0th': zerothIfd, Exif, GPS })
        const exifModified = insert(exifBytes, photo!.dataUrl || '')

        photo!.dataUrl = exifModified
        photo!.webPath = exifModified

        return photo!
    }

    public generateImageUniqueId(): string {
        const chars = '0123456789abcdef'
        let uniqueId = ''
        for (let i = 0; i < 32; i++) {
            uniqueId += chars[Math.floor(Math.random() * 16)]
        }
        return uniqueId
    }

    public async openCameraPermissionSettings(): Promise<void> {
        await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        })
    }

    private async showCameraPermissionMessage(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Camera',
            message: 'To submit a photo, we need access to your camera. '
                + 'Please allow camera access for this app and try again.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Allow...',
                    handler: async () => {

                        // Open camera settings if camera is turned off or permission was explicitly denied
                        const { camera } = await Camera.requestPermissions()
                        if (camera === 'denied') {
                            await this.openCameraPermissionSettings()
                            return
                        }

                        // Otherwise, request the camera permission within the app
                        await Camera.requestPermissions()
                    },
                },
            ],
        })
        await alert.present()
    }

}
