import { Directive, HostListener, Input } from '@angular/core'

import { ExternalLinkService, LinkTarget } from '@app-services'

@Directive({
    selector: '[appExternalLink]',
})
export class ExternalLinkDirective {

    @Input('appExternalLink')
    public href: string | null = null

    @Input('appExternalLinkTarget')
    public target: LinkTarget = '_system'

    constructor(
        private readonly linkService: ExternalLinkService,
    ) {
    }

    @HostListener('click')
    public async onClick(): Promise<void> {
        if (this.href) {
            await this.linkService.open(this.href, this.target)
        }
    }

}
