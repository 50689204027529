<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/account/my-team" />
        </ion-buttons>

        <ion-title>Caught fish</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    <div class="ion-flex ion-h-100 tablet-max-600">

        @if (pendingFishItems?.length) {
            <ion-popover #pendingFishOptionsPopover [dismissOnSelect]="true" size="auto">
                <ng-template>
                    <ion-content [forceOverscroll]="false">
                        <ion-list>
                            <ion-item (click)="confirmRemovePendingFish(selectedPendingFish!)"
                                      [button]="true"
                                      [detail]="false"
                            >
                                <ion-icon slot="start" name="trash-outline" />
                                <ion-label>Remove...</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-content>
                </ng-template>
            </ion-popover>

            <app-image-viewer-modal #pendingFishImageViewerModal
                                    [images]="pendingFishPhotos || []"
            />

            <h3 class="ion-margin-horizontal">Fish ready to submit</h3>
            <ion-list lines="inset">
                @for (fish of pendingFishItems; track fish) {
                    <app-contest-fish-item [pendingFish]="fish"
                                           [showPhoto]="true"
                                           (click)="pendingFishImageViewerModal.initialSlide = $index; pendingFishImageViewerModal.present()"
                    >
                        <ion-button slot="end"
                                    fill="clear"
                                    (click)="showPendingFishOptions($event, fish)"
                                    [disabled]="submitting"
                        >
                            <ion-icon slot="icon-only" size="small" name="ellipsis-vertical" />
                        </ion-button>
                    </app-contest-fish-item>
                }
            </ion-list>

            <ion-button type="button"
                        expand="block"
                        size="large"
                        class="ion-margin ion-color ion-color-primary"
                        (click)="submitAllPendingFish()"
            >
                <span [hidden]="submitting">Submit fish</span>
                <ion-icon [hidden]="submitting" name="cloud-upload-outline" slot="end" />
                <ion-spinner slot="icon-only" [hidden]="! submitting" />
            </ion-button>
        }

        @if (submittedFishItems?.length) {
            <ion-popover #submittedFishFilterPopover [dismissOnSelect]="true" size="auto">
                <ng-template>
                    <ion-content [forceOverscroll]="false">
                        <ion-radio-group [(ngModel)]="submittedFishStatusFilter">
                            <ion-item>
                                <ion-radio [value]="null">All</ion-radio>
                            </ion-item>
                            <ion-item>
                                <ion-radio [value]="FishStatusEnum.Approved">Approved</ion-radio>
                            </ion-item>
                            <ion-item>
                                <ion-radio [value]="FishStatusEnum.Rejected">Rejected</ion-radio>
                            </ion-item>
                            <ion-item>
                                <ion-radio [value]="FishStatusEnum.Unchecked">Pending approval</ion-radio>
                            </ion-item>
                        </ion-radio-group>
                    </ion-content>
                </ng-template>
            </ion-popover>

            <app-image-viewer-modal #submittedFishImageViewerModal
                                    [images]="submittedFishPhotos || []"
            />

            <hr class="ion-color ion-color-light ion-margin" />

            <div class="ion-flex ion-flex-row ion-justify-content-between">
                <h3 class="ion-margin-horizontal">
                    Already submitted fish
                </h3>
                <ion-button fill="clear"
                            size="small"
                            class="ion-margin-horizontal"
                            (click)="submittedFishFilterPopover.present($event)"
                >
                    <ion-icon slot="icon-only"
                              size="small"
                              [name]="submittedFishStatusFilter ? 'funnel' : 'funnel-outline'"
                              class="ion-margin-horizontal-2"
                    />
                </ion-button>
            </div>
            <ion-list lines="inset">
                @for (fish of submittedFishItems; track fish) {
                    <app-contest-fish-item [fish]="fish"
                                           [showFishStatus]="true"
                                           [showPhoto]="true"
                                           [hidden]="submittedFishStatusFilter && submittedFishStatusFilter !== fish.state"
                                           (click)="submittedFishImageViewerModal.initialSlide = $index; submittedFishImageViewerModal.present()"
                    />
                }

                <ion-item class="filter-empty">
                    <app-empty class="ion-w-100">
                        No fish with this status.
                        <ion-button size="small"
                                    class="ion-color ion-color-light ion-margin-top-3"
                                    (click)="submittedFishStatusFilter = null"
                        >
                            <ion-icon slot="start" name="close-outline" />
                            Reset filter
                        </ion-button>
                    </app-empty>
                </ion-item>
            </ion-list>
        }

        @if (pendingFishItems
        && ! pendingFishItems.length
        && submittedFishItems
        && ! submittedFishItems.length) {
            <app-empty icon="fish-outline" class="ion-h-100">
                You haven't added any fish yet
            </app-empty>
        }

        <ion-card [hidden]="! apiError" class="ion-color ion-color-danger ion-margin">
            <ion-card-content class="error-message">
                {{ apiError }}
            </ion-card-content>
        </ion-card>

    </div>

</ion-content>

<ion-footer>
    <ion-toolbar class="ion-text-center">
        <app-add-fish-modal #addFishModal [breakpoints]="[0, 0.6, 1]" [initialBreakpoint]="0.6" />
        <ion-button [disabled]="submitting"
                    class="ion-margin ion-color ion-color-primary"
                    expand="block"
                    (click)="addFishModal.present()"
                    size="large"
        >
            Add a fish
            <ion-icon name="camera-outline" slot="end" />
        </ion-button>
    </ion-toolbar>
</ion-footer>
