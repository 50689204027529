import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    private storageInitialized = false

    constructor(
        private readonly storage: Storage,
    ) {
    }

    public async set(key: string, value: string | object): Promise<void> {
        if (! this.storageInitialized) {
            await this.initializeStorage()
        }
        return new Promise((resolve, reject) => {
            this.storage.set(key, value)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    console.log('Storage set error', error)
                    reject(error)
                })
        })
    }

    public async get(key: string): Promise<string> {
        if (! this.storageInitialized) {
            await this.initializeStorage()
        }
        return new Promise(
            (resolve, reject) => this.storage.get(key)
                .then((data) => {
                    resolve(data)
                })
                .catch((error) => {
                    console.log('Storage get error', error)
                    reject()
                }),
        )
    }

    public async remove(key: string): Promise<any> {
        if (! this.storageInitialized) {
            await this.initializeStorage()
        }
        return this.storage.remove(key)
            .catch((error) => {
                console.log('Storage remove error', error)
                throw error
            })
    }

    public async clear(): Promise<void> {
        if (! this.storageInitialized) {
            await this.initializeStorage()
        }
        return this.storage.clear()
    }

    public async getGroup(prefix?: string): Promise<any[]> {
        const keys = (await this.storage.keys()).filter((key) => key.startsWith(prefix || ''))
        return Promise.all(keys.map((key) => this.get(key)))
    }

    public async removeGroup(prefix?: string): Promise<void> {
        const keys = (await this.storage.keys()).filter((key) => key.startsWith(prefix || ''))
        await Promise.all(keys.map((key) => this.remove(key)))
    }

    private async initializeStorage(): Promise<void> {
        await this.storage.create()
        this.storageInitialized = true
    }

}
