<swiper #swiperComponent
        (touchstart)="$event.stopPropagation()"
        [autoplay]="{ delay: 2000, disableOnInteraction: false }"
        [fadeEffect]="{ crossFade: true }"
        [loop]="true"
        [spaceBetween]="16"
        [speed]="1000"
        class="ion-padding"
        effect="fade"
>
    @for (sponsor of sponsors; track sponsor) {
        <ng-template swiperSlide>
            @if (contestId) {
                <a [routerLink]="'/contest/' + contestId + '/sponsors/' + sponsor.id"
                   routerDirection="root"
                >
                    <img [src]="sponsor.logo"
                         [alt]="sponsor.name"
                    />
                </a>
            } @else {
                <img [src]="sponsor.logo"
                     [alt]="sponsor.name"
                />
            }
        </ng-template>
    }
</swiper>
