import { NgModule } from '@angular/core'

import { StorageService } from '@app-services/storage/storage.service'

@NgModule({
    providers: [
        StorageService,
    ],
})
export class StorageServiceModule {
}
