<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>Top catches</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon name="refresh-outline" slot="icon-only" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

</ion-header>

<ion-content>

    <ion-refresher slot="fixed" (ionRefresh)="refresh($any($event))">
        <ion-refresher-content />
    </ion-refresher>

        @if (topCatches?.length) {
            <div class="ion-flex ion-h-100 tablet-max-600 ion-margin-bottom">
                <ion-list lines="inset">
                    @for (topCatch of topCatches; track topCatch) {
                        <app-contest-fish-item [fish]="topCatch.fish"
                                               [team]="topCatch.team"
                                               [isTopCatch]="topCatch.isTopCatch"
                        />
                    }
                </ion-list>
            </div>

        } @else if (topCatches && ! topCatches.length) {
            <app-empty icon="fish-outline">
                No top catches have been registered (yet)
            </app-empty>
        }

</ion-content>
