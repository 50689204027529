<ng-template #contestFishDetails let-contestFishItem>
    <ion-row>
        <ion-col class="ion-text-center">
            <div class="ion-padding">
                <strong>{{ contestFishItem?.name }}</strong>
            </div>
            @if (contestFishItem?.photo) {
                <div>
                    <app-image-viewer-modal #imageViewerModal [images]="[contestFishItem.photo]" />
                    <img [src]="contestFishItem.photo"
                         alt=""
                         class="contest-fish__image"
                         (click)="imageViewerModal.present()"
                    />
                </div>
            }
        </ion-col>
    </ion-row>
</ng-template>

@if (contestFish?.length === 1) {
    <ng-container *ngTemplateOutlet="contestFishDetails; context: { $implicit: contestFish?.[0] }" />
} @else {
    <swiper #swiperComponent
            [pagination]="true"
            (slideChange)="fishChanged.emit(contestFish[swiperComponent?.swiperRef?.activeIndex])"
    >
        @for (contestFishItem of contestFish; track contestFish) {
            <ng-template swiperSlide>
                <ng-container *ngTemplateOutlet="contestFishDetails; context: { $implicit: contestFishItem }" />
            </ng-template>
        }
    </swiper>
}
