import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import {
    Leaderboard,
    LeaderboardEntriesQuery,
    LeaderboardEntriesQueryService,
    LeaderboardEntry,
    LeaderboardQuery,
    LeaderboardQueryService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class LeaderboardService {

    public leaderboard$ = new Subject<Partial<Leaderboard> | null>()
    public leaderboardEntries$ = new Subject<Partial<LeaderboardEntry>[] | null>()

    private leaderboard: Partial<Leaderboard> | null = null
    private leaderboardEntries: Partial<LeaderboardEntry>[] | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly leaderboardQueryService: LeaderboardQueryService,
        private readonly leaderboardEntriesQueryService: LeaderboardEntriesQueryService,
    ) {
    }

    public async getLeaderboardByContestId(
        contestId: string,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<Leaderboard>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `leaderboard.${contestId}`)
        const leaderboard$ = this.leaderboardQueryService.fetch({ contestId }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<LeaderboardQuery>) => {
                this.leaderboard = result.data.leaderboard as Partial<Leaderboard>
                this.leaderboard$.next(this.leaderboard)

                return this.leaderboard
            }),
        )

        try {
            return await lastValueFrom(leaderboard$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getLeaderboardEntries(
        contestId: string,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<LeaderboardEntry>[]> {
        const fetchPolicy = await this.apiHelperService
            .getFetchPolicy(cacheOptions, `contest.leaderboardEntries.${contestId}`)
        const leaderboardEntries$ = this.leaderboardEntriesQueryService
            .fetch({ contestId }, { fetchPolicy })
            .pipe(
                map((result: ApolloQueryResult<LeaderboardEntriesQuery>) => {
                    this.leaderboardEntries = result.data.leaderboard.leaderboardEntries as Partial<LeaderboardEntry>[]
                    this.leaderboardEntries$.next(this.leaderboardEntries)

                    return this.leaderboardEntries
                }),
            )

        try {
            return await lastValueFrom(leaderboardEntries$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
