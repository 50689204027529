import { NgModule } from '@angular/core'

import { SlidesPerViewAutoFixDirective } from '@app-directives/ui/swiper/slides-per-view-auto-fix.directive'

const uiDirectives = [
    SlidesPerViewAutoFixDirective,
]

@NgModule({
    declarations: [
        ...uiDirectives,
    ],
    exports: [
        ...uiDirectives,
    ],
})
export class UiDirectivesModule {
}
