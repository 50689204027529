import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController } from '@ionic/angular'

import { AuthService, FormHelperService } from '@app-services'

@Component({
    selector: 'app-auth-forgot-password-page',
    templateUrl: './auth-forgot-password.page.html',
    styleUrls: ['./auth-forgot-password.page.scss'],
})
export class AuthForgotPasswordPage implements OnInit {

    public form: FormGroup | null = null
    public loading = false
    public requestSent = false
    public apiError: string | null = null

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly alertController: AlertController,
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.email]),
        })
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.email && ! this.form?.controls?.email?.value) {
                this.form?.controls?.email?.setValue(params.email)
            }
        })
    }

    // noinspection JSUnusedGlobalSymbols
    public ionViewWillEnter(): void {
        this.requestSent = false
    }

    public async submit(): Promise<void> {
        this.apiError = null

        await this.formHelperService.hideKeyboard()

        if (this.loading) {
            return
        }

        if (! this.form?.valid) {
            await this.formHelperService.reportFormErrors(this.form!)
            return
        }

        this.loading = true

        try {
            await this.authService.forgotPassword(this.form.controls.email.value)
            this.loading = false

            const message = await this.alertController.create({
                header: 'Password reset',
                message: 'We have sent you an email with instructions to reset your password.',
                buttons: ['Ok'],
            })
            await message.present()

            await this.router.navigate(
                ['/auth/login'],
                { queryParams: { email: this.form.controls.email.value }, replaceUrl: true },
            )

        } catch (e: any) {
            this.loading = false
            this.apiError = e?.message
            await this.formHelperService.scrollFirstErrorIntoView()
        }
    }

}
