import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'

import { ContestFish } from '@app-graphql'

@Component({
    selector: 'app-contest-fish',
    templateUrl: './contest-fish.component.html',
    styleUrls: ['./contest-fish.component.scss'],
})
export class ContestFishComponent implements OnChanges {

    @Input()
    public contestFish: Partial<ContestFish>[] | null = null

    @Output()
    public fishChanged = new EventEmitter<Partial<ContestFish>>()

    public ngOnChanges(): void {
        if (this.contestFish?.length) {
            this.fishChanged.emit(this.contestFish[0])
        }
    }

}
