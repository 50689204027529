import { Component, Input } from '@angular/core'

import { Contest } from '@app-graphql'

@Component({
    selector: 'app-contest-menu',
    templateUrl: './contest-menu.component.html',
    styleUrls: ['./contest-menu.component.scss'],
})
export class ContestMenuComponent {

    @Input()
    public contest: Partial<Contest> | null = null

    @Input()
    public mode: 'accordion' | 'list' = 'accordion'

}
