import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'

import { Message } from '@app-graphql'
import { CacheTtl, MessageService } from '@app-services'

@Component({
    selector: 'app-general-messages-detail-page',
    templateUrl: './general-messages-detail.page.html',
    styleUrls: ['./general-messages-detail.page.scss'],
})
export class GeneralMessagesDetailPage implements OnDestroy {

    public messageId: string

    public message: Partial<Message> | null = null
    public loading = false

    private message$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly messageService: MessageService,
    ) {
        this.message$ = this.messageService.message$.subscribe((message) => {
            this.message = message
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.messageId) {
                this.messageId = params.messageId
                await this.loadMessage()
            }
        })
    }

    public async ngOnDestroy(): Promise<void> {
        this.message$?.unsubscribe()
    }

    public async loadMessage(): Promise<void> {
        this.loading = true
        await this.messageService.getMessageById(this.messageId, { cacheTtl: CacheTtl.Month })
        this.loading = false
    }

}
