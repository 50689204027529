import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController } from '@ionic/angular'

import { Contest, Team, User } from '@app-graphql'

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnChanges {

    @Input()
    public user: Partial<User> | null = null

    public activeContest: Partial<Contest> | null = null
    public activeTeam: Partial<Team> | null = null

    constructor(
        private readonly alertController: AlertController,
        private readonly router: Router,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.user) {
            this.activeTeam = this.user?.teams?.find((team) => team?.isActive) || null
            this.activeContest = this.activeTeam?.contests?.find((contest) => contest?.isCurrentlyActive) || null
        }
    }

    public async confirmLogout(): Promise<void> {
        const message = await this.alertController.create({
            header: 'Confirm',
            message: 'Are you sure you want to log out?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Log out',
                    handler: () => {
                        this.user = null
                        this.router.navigate(['/auth/login'], {
                            queryParams: { logout: true },
                            replaceUrl: true,
                        })
                    },
                },
            ],
        })
        await message.present()
    }

}
