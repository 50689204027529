import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import { News, NewsItemQuery, NewsItemQueryService, NewsQuery, NewsQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class NewsService {

    public news$ = new Subject<Partial<News>[] | null>()
    public newsItem$ = new Subject<Partial<News> | null>()

    private news: Partial<News>[] | null = null
    private newsItem: Partial<News> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly newsQueryService: NewsQueryService,
        private readonly newsItemQueryService: NewsItemQueryService,
    ) {
    }

    public async getNews(contestId: string, cacheOptions?: CacheOptions): Promise<Partial<News>[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `news.contest-${contestId}`)
        const news$ = this.newsQueryService.fetch({ contestId }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<NewsQuery>) => {
                this.news = result.data.news as Partial<News>[]
                this.news$.next(this.news)

                return this.news
            }),
        )

        try {
            return await lastValueFrom(news$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getNewsItemById(id: string, cacheOptions?: CacheOptions): Promise<Partial<News>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `news.item-${id}`)
        const newsItem$ = this.newsItemQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<NewsItemQuery>) => {
                this.newsItem = result.data.newsItem as Partial<News>
                this.newsItem$.next(this.newsItem)

                return this.newsItem
            }),
        )

        try {
            return await lastValueFrom(newsItem$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
