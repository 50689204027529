<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button [defaultHref]="(
                '/contest/' + contest?.id
                    + (isStandalone ? '/standalone-leaderboard' : '/leaderboard')
            )" />
        </ion-buttons>

        <ion-title>Team details</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon name="refresh-outline" slot="icon-only" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (team) {
        <app-contest-team-information [team]="team" />
    }

    @if (leaderboardEntry) {
        <app-contest-team-leaderboard-results [leaderboardEntry]="leaderboardEntry" />
    }

    @if (contest) {
        <app-contest-team-fish [contest]="contest" [team]="team" />
    }

</ion-content>
