import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppConfig } from '@app-config'
import { AppModule } from './app/app.module'

if (AppConfig.environment === 'production') {
    enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err))
