import { Component, Input } from '@angular/core'

import { LeaderboardEntry } from '@app-graphql'

@Component({
    selector: 'app-contest-team-leaderboard-results',
    templateUrl: './contest-team-leaderboard-results.component.html',
    styleUrls: ['./contest-team-leaderboard-results.component.scss'],
})
export class ContestTeamLeaderboardResultsComponent {

    @Input()
    public leaderboardEntry: Partial<LeaderboardEntry> | null = null

}
