import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Photo } from '@capacitor/camera'
import { Position } from '@capacitor/geolocation'
import { Platform } from '@ionic/angular'
import { Subscription } from 'rxjs'

import { Contest, Team, User } from '@app-graphql'
import { PendingFish } from '@app-interfaces'
import { BaseModal } from '@app-modals/base.modal'
import {
    ContestService,
    FileHelperService,
    FishService,
    FormHelperService,
    LocationHelperService,
    PhotoService,
    StatusBarStyleService,
    UserService,
} from '@app-services'

@Component({
    selector: 'app-add-fish-modal',
    templateUrl: './add-fish.modal.html',
    styleUrls: ['./add-fish.modal.scss'],
})
export class AddFishModal extends BaseModal implements AfterViewInit, OnDestroy {

    @ViewChild('loadingElement')
    public loadingElement: HTMLIonLoadingElement | null = null

    public contestId: string
    public user: Partial<User> | null = null
    public team: Partial<Team> | null = null
    public contest: Partial<Contest> | null = null

    public loading = false

    public form: FormGroup | null = null
    public photo: Photo | null = null

    private contest$: Subscription | null = null
    private user$: Subscription | null = null
    private locationTimer: number | null = null
    private currentPosition: Position | undefined

    constructor(
        protected readonly statusBarStyleService: StatusBarStyleService,
        private readonly contestService: ContestService,
        private readonly fileHelperService: FileHelperService,
        private readonly fishService: FishService,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly locationHelperService: LocationHelperService,
        private readonly photoService: PhotoService,
        private readonly platform: Platform,
        private readonly router: Router,
        private readonly userService: UserService,
    ) {
        super(statusBarStyleService)

        this.form = this.formBuilder.group({
            photo: new FormControl(null, [Validators.required]),
            uniqueId: new FormControl(null, [Validators.required]),
        })

        this.contest$ = this.contestService.contest$.subscribe((contest) => {
            this.contest = contest
        })

        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user
            this.team = user?.teams?.find((team) => team?.isActive) || null
        })
    }

    public ngAfterViewInit(): void {
        this.modal?.ionModalWillPresent.subscribe(async () => {
            this.loading = true

            await this.userService.getUser()

            const contest = this.team?.contests?.find((c) => c.isCurrentlyActive)
            if (contest) {
                this.contestId = contest.id
                await this.contestService.getContestById(this.contestId)
            } else {
                this.contestId = null
            }

            this.form.controls.uniqueId.setValue(this.photoService.generateImageUniqueId())

            // Expand to full size if a photo was already selected previously
            if (this.photo) {
                setTimeout(async () => this.modal.setCurrentBreakpoint(1), 500)
            }

            this.loading = false
        })
    }

    public ngOnDestroy(): void {
        this.contest$?.unsubscribe()
        this.user$?.unsubscribe()
    }

    public async getLocation(): Promise<boolean> {
        this.currentPosition = await this.locationHelperService.getLocation()

        // Something went wrong, hide the loader
        if (! this.currentPosition) {
            await this.loadingElement?.dismiss()
        }

        return false
    }

    public async takePhoto(): Promise<void> {

        // We need the user's location to add to the photo's EXIF data
        // This is used to verify the location of the fish
        this.loadingElement!.message = 'Getting your current location...'

        window.clearTimeout(this.locationTimer)
        this.locationTimer = window.setTimeout(() => {
            this.loadingElement!.message = 'Retrieving GPS data. This may take a few seconds...'
        }, 2500)

        await this.loadingElement?.present()
        await this.getLocation()

        window.clearTimeout(this.locationTimer)
        if (! this.currentPosition) {
            return
        }

        // Open the camera and let the user take a photo
        this.loadingElement!.message = 'Opening your camera...'

        let photo
        try {
            photo = await this.photoService.takePhoto()
        } catch (e: any) {
            await this.loadingElement?.dismiss()
            return
        }

        if (! photo) {
            await this.loadingElement?.dismiss()
            return
        }

        // Add the EXIF data to the photo
        this.loadingElement!.message = 'Processing your photo...'
        const uniqueId = this.form?.controls?.uniqueId?.value
        this.photo = this.photoService.addExifDataToPhoto({
            photo,
            uniqueId,
            position: this.currentPosition,
            user: this.user,
        })

        this.form?.controls?.photo?.setValue(this.fileHelperService.dataUrlToBlob(this.photo.dataUrl))

        await this.loadingElement?.dismiss()
    }

    public clearPhoto(): void {
        this.photo = null
        this.form?.controls?.photo?.setValue(null)
        this.form?.controls?.photo?.markAsDirty()
    }

    public async saveFish(): Promise<void> {
        await this.formHelperService.hideKeyboard()

        if (! this.form?.valid) {
            await this.formHelperService.reportFormErrors(this.form!)
            return
        }

        this.form.markAsPristine()

        // If this is a new item, or the photo was changed, save the photo
        let photoFileUri: string
        if (this.form.controls.photo?.value instanceof Blob) {
            if (this.platform.is('hybrid')) {
                photoFileUri = await this.fileHelperService.writeBlobToFile(
                    this.form.controls.photo?.value,
                    `pending-fish/${this.form.controls.uniqueId?.value}.jpg`,
                )
            } else {
                photoFileUri = this.photo!.webPath!
            }
        }

        // Add the fish to the pending fish list
        const latitude: string = `${this.currentPosition?.coords?.latitude}`
        const longitude: string = `${this.currentPosition?.coords?.longitude}`
        const pendingFish: PendingFish = {
            uniqueId: this.form.controls.uniqueId?.value,
            contestId: this.contestId,
            photo: null,
            photoFileUri,
            latitude,
            longitude,
            teamId: this.team?.id,
        }
        await this.fishService.addPendingFish(pendingFish)

        await this.router.navigate(['/account/fish'])
        await this.dismiss()

        window.setTimeout(() => {
            this.form?.reset()
            this.photo = null
        }, 1000)
    }

}
