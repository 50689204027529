import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { Sponsor } from '@app-graphql'
import { SponsorService } from '@app-services'

@Component({
    selector: 'app-contest-sponsor-detail-page',
    templateUrl: './contest-sponsor-detail.page.html',
    styleUrls: ['./contest-sponsor-detail.page.scss'],
})
export class ContestSponsorDetailPage implements OnDestroy {

    public sponsor: Partial<Sponsor> | null = null
    public contestId: string | null = null
    public sponsorId: string | null = null
    public loading = false

    private sponsor$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly sponsorService: SponsorService,
    ) {
        this.sponsor$ = this.sponsorService.sponsor$.subscribe((sponsor: Partial<Sponsor> | null) => {
            this.sponsor = sponsor
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
            }
            if (params.sponsorId) {
                this.sponsorId = params.sponsorId
                await this.loadSponsor()
            }
        })
    }

    public ngOnDestroy(): void {
        this.sponsor$?.unsubscribe()
    }

    public async loadSponsor(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        this.loading = true

        if (this.sponsorId) {
            await this.sponsorService.getSponsorById(this.sponsorId, { clearCache: !! e })
        }

        await e?.detail?.complete()
        this.loading = false
    }

}
