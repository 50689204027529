<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>
        <ion-title>Live stream</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">

    <div class="tablet-max-600">
        <ion-list lines="inset">
            <ion-item [button]="true"
                      [detail]="true"
                      appExternalLink="https://www.facebook.com/predatortour"
                      appExternalLinkTarget="_blank"
            >
                <ion-icon name="logo-facebook" slot="start" />
                <ion-label>Facebook</ion-label>
            </ion-item>
            <ion-item [button]="true"
                      [detail]="true"
                      appExternalLink="https://www.twitch.com/predatortour"
                      appExternalLinkTarget="_blank"
            >
                <ion-icon name="logo-twitch" slot="start" />
                <ion-label>Twitch</ion-label>
            </ion-item>
            <ion-item [button]="true"
                      [detail]="true"
                      appExternalLink="https://www.youtube.com/predatortour"
                      appExternalLinkTarget="_system"
            >
                <ion-icon name="logo-youtube" slot="start" />
                <ion-label>YouTube</ion-label>
            </ion-item>
        </ion-list>
    </div>

</ion-content>
