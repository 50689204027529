import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

import { AuthService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
    }

    public async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isAuthenticated = await this.authService.isAuthenticated()
        if (! isAuthenticated && ! this.authService.authenticationInProgress) {
            await this.router.navigate(
                ['/auth/login'],
                {
                    replaceUrl: true,
                    queryParams: { returnUrl: state.url || null },
                },
            )

            return false
        }

        return true
    }

}
