import { Injectable } from '@angular/core'
import { Browser } from '@capacitor/browser'
import { Platform } from '@ionic/angular'

export type LinkTarget = '_system' | '_blank' | null

@Injectable()
export class ExternalLinkService {

    constructor(
        private readonly platform: Platform,
    ) {
    }

    public async open(url: string, target: LinkTarget = '_system'): Promise<void> {
        const hasSpecialUrlScheme = ! (/^https?:/i).test(url) && (/^([a-z0-9]+:)/i).test(url)

        if (target === '_system' || hasSpecialUrlScheme) {
            this.openUsingSystem(url)
            return
        }

        await this.openUsingViewController(url)
    }

    private async openUsingViewController(url: string): Promise<void> {
        if (! this.platform.is('hybrid')) {
            window.open(url)
            return
        }

        const toolbarColor = window.getComputedStyle(document.documentElement)
            .getPropertyValue('--ion-color-tertiary').trim() || '#333333'

        await Browser.open({ url, toolbarColor })
    }

    private openUsingSystem(url: string): void {
        if (! this.platform.is('hybrid') && ! url.startsWith('http')) {
            location.href = url
            return
        }

        window.open(url)
    }

}
