import { NgModule } from '@angular/core'

import { TextPipesModule } from '@app-pipes/text/text-pipes.module'
import { VendorSharedModule } from '@app-modules/vendor-shared.module'
import {
    EmptyComponent,
    ProgressBarComponent,
    PushNotificationReminderComponent,
    VideoBackgroundComponent,
} from '@app-components'

const uiComponents = [
    EmptyComponent,
    ProgressBarComponent,
    PushNotificationReminderComponent,
    VideoBackgroundComponent,
]

@NgModule({
    imports: [
        TextPipesModule,
        VendorSharedModule,
    ],
    exports: [
        ...uiComponents,
    ],
    declarations: [
        ...uiComponents,
    ],
})
export class UiComponentsModule {
}
