import { NgModule } from '@angular/core'

import { ExternalContentDirective } from '@app-directives/text/external-content/external-content.directive'

const textDirectives = [
    ExternalContentDirective,
]

@NgModule({
    declarations: [
        ...textDirectives,
    ],
    exports: [
        ...textDirectives,
    ],
})
export class TextDirectivesModule {
}
