<ng-template #menu>
    @for (dynamicMenuItem of dynamicMenuItems; track dynamicMenuItem) {
        <ion-menu-toggle auto-hide="false">
            @if (dynamicMenuItem!.url) {
                <ion-item [button]="true"
                          [detail]="false"
                          [appExternalLink]="dynamicMenuItem!.url"
                          appExternalLinkTarget="_blank"
                          class="ion-color ion-color-primary"
                >
                    <ion-icon slot="start" name="open-outline" />
                    <ion-label>{{ dynamicMenuItem!.title }}</ion-label>
                </ion-item>
            } @else {
                <ion-item [button]="true"
                          [detail]="false"
                          [routerLink]="'/general/page/' + dynamicMenuItem!.id"
                          routerDirection="root"
                          class="ion-color ion-color-primary"
                >
                    <ion-icon slot="start" name="information-circle-outline" />
                    <ion-label>{{ dynamicMenuItem!.title }}</ion-label>
                </ion-item>
            }
        </ion-menu-toggle>
    }
</ng-template>

@if (dynamicMenuItems?.length) {
    @if (mode === 'accordion') {
        <ion-accordion value="information">
            <ion-item slot="header"
                      class="ion-color ion-color-primary"
            >
                <ion-icon slot="start" name="information-circle-outline" />
                <ion-label>Information</ion-label>
            </ion-item>
            <div slot="content">
                <ng-container *ngTemplateOutlet="menu" />
            </div>
        </ion-accordion>
    } @else {
        <ng-container *ngTemplateOutlet="menu" />
    }
}
