import { Component, Input } from '@angular/core'
import { Icon } from 'ionicons/dist/types/components/icon/icon'

@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent {

    @Input()
    public icon?: string | Icon | null = null

    @Input()
    public height: string | 'auto' | '100%' = '100%'

}
