@if (user) {
    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  routerLink="/account/my-team"
                  routerDirection="root"
                  class="ion-color ion-color-primary"
        >
            <ion-icon slot="start" name="people-outline" />
            <ion-label>My team</ion-label>
        </ion-item>
    </ion-menu-toggle>
    @if (activeTeam && activeContest) {
        <app-add-fish-modal #addFishModal [breakpoints]="[0, 0.6, 1]" [initialBreakpoint]="0.6" />
        <ion-menu-toggle auto-hide="false">
            <ion-item [button]="true"
                      [detail]="false"
                      (click)="addFishModal.present()"
                      class="ion-color ion-color-primary"
            >
                <ion-icon slot="start" name="camera-outline" />
                <ion-label>Take photo</ion-label>
            </ion-item>
        </ion-menu-toggle>
    }
    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  (click)="confirmLogout()"
                  class="ion-color ion-color-primary"
        >
            <ion-icon slot="start" name="log-out-outline" />
            <ion-label>Log out</ion-label>
        </ion-item>
    </ion-menu-toggle>
} @else {
    <ion-menu-toggle auto-hide="false">
        <ion-item [button]="true"
                  [detail]="false"
                  routerLink="/auth/login"
                  routerDirection="root"
                  class="ion-color ion-color-primary"
        >
            <ion-icon slot="start" name="person-outline" />
            <ion-label>Login</ion-label>
        </ion-item>
    </ion-menu-toggle>
}
