import { AfterViewInit, Directive, Input, ViewChild } from '@angular/core'
import { Style } from '@capacitor/status-bar'
import { IonModal } from '@ionic/angular'

import { StatusBarStyleService } from '@app-services'

@Directive()
export abstract class BaseModal implements AfterViewInit {

    @Input()
    public trigger?: string

    @Input()
    public breakpoints: number[] | undefined = undefined

    @Input()
    public initialBreakpoint: number = 1

    @Input()
    public handle: boolean = false

    @ViewChild(IonModal)
    protected modal: IonModal | null = null

    protected previousStatusBarStyle: Style | null = null
    protected statusBarStyle: Style = Style.Dark

    constructor(
        protected readonly statusBarStyleService: StatusBarStyleService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.modal?.ionModalWillPresent
            .subscribe(async () => {
                this.previousStatusBarStyle = this.statusBarStyleService.statusBarStyle || null
                await this.statusBarStyleService.setStatusBarStyle(this.statusBarStyle)
            })
        this.modal?.ionModalWillDismiss
            .subscribe(() => this.statusBarStyleService.setStatusBarStyle(this.previousStatusBarStyle || Style.Dark))
    }

    public async present(): Promise<void> {
        await this.modal?.present()
    }

    public async dismiss(): Promise<void> {
        await this.modal?.dismiss()
    }

}
