import { Component, OnDestroy } from '@angular/core'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { Contest, LeaderboardEntry, Team, User } from '@app-graphql'
import { CacheTtl, ContestService, LeaderboardService, UserService } from '@app-services'

@Component({
    selector: 'app-account-my-team-page',
    templateUrl: './account-my-team.page.html',
    styleUrls: ['./account-my-team.page.scss'],
})
export class AccountMyTeamPage implements OnDestroy {

    public contestId: string | null = null
    public teamId: string | null = null

    public user: Partial<User> | null = null
    public contest: Partial<Contest> | null = null
    public team: Partial<Team> | null = null
    public leaderboardEntry: Partial<LeaderboardEntry> | null = null

    public loading = false

    private user$: Subscription | null = null
    private contest$: Subscription | null = null
    private team$: Subscription | null = null
    private leaderboardEntry$: Subscription | null = null

    constructor(
        private readonly contestService: ContestService,
        private readonly leaderboardService: LeaderboardService,
        private readonly userService: UserService,
    ) {
        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user
            this.teamId = (user?.teams?.find((team) => team?.isActive) || null)?.id || null
        })

        this.contest$ = this.contestService.contest$.subscribe((contest: Partial<Contest> | null) => {
            this.contest = contest
        })

        this.team$ = this.contestService.team$.subscribe((team: Partial<Team> | null) => {
            this.team = team
        })

        this.leaderboardEntry$ = this.leaderboardService.leaderboardEntries$
            .subscribe((leaderboardEntries: Partial<LeaderboardEntry>[] | null) => {
                this.leaderboardEntry = leaderboardEntries
                    ?.find((leaderboardEntry) => leaderboardEntry?.team?.id === this.team?.id) || null
            })
    }

    // noinspection JSUnusedGlobalSymbols
    public async ionViewWillEnter(): Promise<void> {
        await this.loadData()
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
        this.contest$?.unsubscribe()
        this.team$?.unsubscribe()
        this.leaderboardEntry$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadData(true)
        await e?.detail?.complete()
    }

    public async loadData(clearCache = false): Promise<void> {
        this.loading = true

        let contest: Partial<Contest> | null = null

        await this.userService.getUser({ clearCache })
        if (this.teamId) {
            await this.contestService.getTeamById(
                this.teamId,
                { clearCache, cacheTtl: CacheTtl.Minute * 5 },
            )
            contest = this.team?.contests?.find((c) => c.isCurrentlyActive)
        }

        if (contest) {
            this.contestId = contest.id
            await Promise.all([
                this.contestService.getContestById(this.contestId, { clearCache }),
                this.leaderboardService.getLeaderboardEntries(
                    this.contestId,
                    { clearCache, cacheTtl: CacheTtl.Minute * 5 },
                ),
            ])
        } else {
            this.contestId = null
        }

        this.loading = false
    }

}
