@if (filteredCatches !== null) {
    <div class="ion-text-center ion-padding">

        @if (filteredCatches.length) {

            @if (showPhoto) {
                <app-image-viewer-modal #catchImageViewerModal [images]="filteredCatchPhotos" />

                <ion-list lines="full">
                    @for (fish of filteredCatches; track fish) {
                        <ion-item [button]="true"
                                  [detail]="true"
                                  (click)="catchImageViewerModal.initialSlide = $index; catchImageViewerModal.present()"
                        >
                            <ion-thumbnail slot="start" class="ion-margin-vertical-2">
                                <img [src]="fish?.photo" alt="" />
                            </ion-thumbnail>

                            <div class="fish-item__data">
                                <div class="ion-font-size-3">
                                    {{ fish?.length }}
                                    <span class="ion-font-size-1">mm</span>
                                </div>
                            </div>
                        </ion-item>
                    }
                </ion-list>
            } @else {
                @for (fish of filteredCatches; track fish) {
                    <div class="ion-font-size-4 ion-margin-vertical">
                        {{ fish.length }} mm
                    </div>
                }
            }
        } @else {
            <app-empty icon="fish-outline" height="150px">
                <ng-content select="[slot=empty]" />
            </app-empty>
        }

        <p>{{ filteredCatches.length }} / {{ contestFish.requiredToContinue }}</p>

    </div>
}
