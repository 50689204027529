import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import { Sponsor, SponsorQuery, SponsorQueryService, SponsorsQuery, SponsorsQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class SponsorService {

    public sponsors$ = new Subject<Partial<Sponsor>[] | null>()
    public sponsor$ = new Subject<Partial<Sponsor> | null>()

    private sponsors: Partial<Sponsor>[] | null = null
    private sponsor: Partial<Sponsor> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly sponsorsQueryService: SponsorsQueryService,
        private readonly sponsorQueryService: SponsorQueryService,
    ) {
    }

    public async getSponsors(contestId: string, cacheOptions?: CacheOptions): Promise<Partial<Sponsor>[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `sponsors.contest-${contestId}`)
        const sponsors$ = this.sponsorsQueryService.fetch({ contestId }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<SponsorsQuery>) => {
                this.sponsors = result.data.sponsors as Partial<Sponsor>[]
                this.sponsors$.next(this.sponsors)

                return this.sponsors
            }),
        )

        try {
            return await lastValueFrom(sponsors$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getSponsorById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Sponsor>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `sponsors.item-${id}`)
        const sponsor$ = this.sponsorQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<SponsorQuery>) => {
                this.sponsor = result.data.sponsor as Partial<Sponsor>
                this.sponsor$.next(this.sponsor)

                return this.sponsor
            }),
        )

        try {
            return await lastValueFrom(sponsor$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
