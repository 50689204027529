<ion-app>
    <ion-split-pane contentId="content" when="md">

        <ion-menu #menu
                  (ionDidOpen)="sponsorsLogoSwiperComponent?.start()"
                  class="ion-color ion-color-primary"
                  contentId="content"
        >
            <ion-header>
                <img alt="" src="/assets/images/menu-logo.png" />
            </ion-header>

            <ion-content [forceOverscroll]="false" class="ion-color ion-color-primary">
                <ion-list>
                    <ion-accordion-group [value]="activeMenuGroup">
                        <ion-menu-toggle auto-hide="false">
                            <ion-item [button]="true"
                                      [detail]="false"
                                      class="ion-color ion-color-primary"
                                      routerDirection="root"
                                      routerLink="/"
                            >
                                <ion-icon name="home-outline" slot="start" />
                                <ion-label>Home</ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        @if (generalData?.activeContest) {
                            <app-contest-menu [contest]="generalData.activeContest"
                                              [mode]="generalData?.dynamicMenuItems?.length ? 'accordion' : 'list'"
                            />
                        }

                        @if (generalData?.dynamicMenuItems?.length) {
                            <app-dynamic-menu [dynamicMenuItems]="generalData.dynamicMenuItems"
                                              [mode]="generalData?.activeContest ? 'accordion' : 'list'"
                            />
                        }

                        <ion-menu-toggle auto-hide="false">
                            <ion-item [button]="true"
                                      [detail]="false"
                                      class="ion-color ion-color-primary"
                                      routerDirection="root"
                                      routerLink="/general/messages"
                            >
                                <ion-icon name="notifications-outline" slot="start" />
                                <ion-label>Messages</ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <app-user-menu [user]="user" />

                    </ion-accordion-group>
                </ion-list>
            </ion-content>

            <ion-footer>

                <div [class.hidden]="! generalData?.sponsors?.length"
                     class="ion-padding-horizontal ion-padding-top-2 ion-font-size-1 ion-text-center"
                >
                    We are proudly sponsored by:
                </div>

                <app-sponsors-logo-swiper #sponsorsLogoSwiperComponent
                                          [contestId]="generalData?.activeContest?.id"
                                          (click)="$any(menu).close()"
                />

                <div class="ion-padding ion-font-size-1 ion-text-center">
                    App version {{ appVersion }}
                </div>
            </ion-footer>

        </ion-menu>

        <ion-router-outlet id="content" />

    </ion-split-pane>
</ion-app>
