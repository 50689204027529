import { NgModule } from '@angular/core'
import { Style } from '@capacitor/status-bar'

import { PhotoService, StatusBarStyleService } from '@app-services'

@NgModule({
    providers: [
        PhotoService,
        StatusBarStyleService.withConfig({
            ignoredRoutes: [],
            defaultStatusBarStyle: Style.Dark,
        }),
    ],
})
export class NativeServiceModule {
}
