import { Directive, HostListener } from '@angular/core'
import { ExternalLinkService, LinkTarget } from '@app-services'

@Directive({
    selector: '[appExternalContent]',
})
export class ExternalContentDirective {

    constructor(
        private readonly linkService: ExternalLinkService,
    ) {
    }

    @HostListener('click', ['$event'])
    public async onClick(e: MouseEvent): Promise<void> {
        const targetElement = e.target as HTMLElement

        // Handle hyperlinks
        if (targetElement?.tagName === 'A') {
            e.preventDefault()

            const url: string = targetElement.getAttribute('href') || ''
            const extension = url.split('.')?.pop()?.split('?')[0].toLowerCase() || ''
            let target: LinkTarget = '_blank'

            // Use _system for downloads, explicitly set targets and certain files
            if (
                targetElement.hasAttribute('download')
                || targetElement.getAttribute('target') === '_system'
                || ['pdf', 'png', 'gif', 'jpg', 'jpeg'].includes(extension)
            ) {
                target = '_system'
            }

            await this.linkService.open(url, target)
        }
    }

}
