import { NgModule } from '@angular/core'

import { VendorSharedModule } from '@app-modules/vendor-shared.module'
import { ModalsSharedModule } from '@app-modals/modals-shared.module'
import { AddFishModal } from '@app-modals'
import { ImageModalsModule } from '@app-modals/image/image-modals.module'
import { UiComponentsModule } from '@app-components/ui/ui-components.module'

const addFishModals = [
    AddFishModal,
]

@NgModule({
    imports: [
        ModalsSharedModule,
        VendorSharedModule,
        ImageModalsModule,
        UiComponentsModule,
    ],
    declarations: [
        ...addFishModals,
    ],
    exports: [
        ...addFishModals,
    ],
})
export class AddFishModalsModule {
}
