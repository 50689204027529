import { NgModule } from '@angular/core'

import { ContestMenuComponent, DynamicMenuComponent, UserMenuComponent } from '@app-components'
import { NavigationDirectivesModule } from '@app-directives/navigation/navigation-directives.module'
import { VendorSharedModule } from '@app-modules/vendor-shared.module'
import { AddFishModalsModule } from '@app-modals/add-fish/add-fish-modals.module'

const menuComponents = [
    ContestMenuComponent,
    DynamicMenuComponent,
    UserMenuComponent,
]

@NgModule({
    imports: [
        NavigationDirectivesModule,
        VendorSharedModule,
        AddFishModalsModule,
    ],
    exports: [
        ...menuComponents,
    ],
    declarations: [
        ...menuComponents,
    ],
})
export class MenuComponentsModule {
}
