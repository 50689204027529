import { Pipe, PipeTransform } from '@angular/core'

import { User } from '@app-graphql'

@Pipe({
    name: 'userNames',
})
export class UserNamesPipe implements PipeTransform {

    public transform(users: Partial<User>[] | null, separator = ', '): string {
        return users?.map((i) => `${i.firstName} ${i.lastName}`).join(separator) || ''
    }

}
