import { NgModule } from '@angular/core'

import { VendorSharedModule } from '@app-modules/vendor-shared.module'
import { SponsorsLogoSwiperComponent } from '@app-components'

const sponsorsComponents = [
    SponsorsLogoSwiperComponent,
]

@NgModule({
    imports: [
        VendorSharedModule,
    ],
    exports: [
        ...sponsorsComponents,
    ],
    declarations: [
        ...sponsorsComponents,
    ],
})
export class SponsorsComponentsModule {
}
