<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/general/messages" />
        </ion-buttons>

        <ion-title>Message</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }
    </ion-toolbar>
</ion-header>

<ion-content>

    @if (message) {
        <div class="ion-flex ion-h-100 tablet-max-600 ion-padding">
            <h1>{{ message.title }}</h1>
            <div class="ion-text-muted ion-font-size-1 ion-margin-bottom-4">
                {{ message.createdAt | date : 'MMMM d, y - HH:mm' }}
            </div>
<!--            <div [innerHTML]="message.message | safe : 'html'" appExternalContent></div>-->
            <div [innerHTML]="message.content | safe : 'html'" appExternalContent></div>
        </div>
    }

</ion-content>
