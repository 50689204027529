import { CommonModule, NgOptimizedImage } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { MaskitoModule } from '@maskito/angular'
import { DateFnsModule } from 'ngx-date-fns'
import { LinkyModule } from 'ngx-linky'
import { SwiperModule } from 'swiper/angular'
import SwiperCore, { Autoplay, EffectFade, Keyboard, Pagination, Zoom } from 'swiper'

SwiperCore.use([Autoplay, EffectFade, Keyboard, Pagination, Zoom])

@NgModule({
    imports: [
        NgOptimizedImage,
    ],
    exports: [
        CommonModule,
        DateFnsModule,
        IonicModule,
        LinkyModule,
        FormsModule,
        MaskitoModule,
        RouterModule,
        ReactiveFormsModule,
        SwiperModule,
        NgOptimizedImage,
    ],
})
export class VendorSharedModule {
}
