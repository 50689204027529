import { Injectable } from '@angular/core'
import { ApolloError, FetchPolicy } from '@apollo/client/core'
import { Platform, ToastController } from '@ionic/angular'
import { Apollo } from 'apollo-angular'

import { AppConfig } from '@app-config'
import { StorageService } from '@app-services/storage'

export interface CacheOptions {
    clearCache?: boolean;
    cacheTtl?: number;
}

export enum CacheTtl {
    None = 0,
    Minute = 60,
    Hour = 3600,
    Day = 86400,
    Week = 604800,
    Month = 2592000,
    Year = 31536000,
}

export interface PaginationOptions {
    page?: number;
    first: number;
}

@Injectable({
    providedIn: 'root',
})
export class ApiHelperService {

    protected constructor(
        private readonly apollo: Apollo,
        private readonly storageService: StorageService,
        private readonly toastController: ToastController,
        private readonly platform: Platform,
    ) {
        this.platform.ready().then(async () => {
            await this.clearExpiredCacheTimestamps()
        })
    }

    public getErrorMessageFromApolloError(error: ApolloError): string {
        if (! navigator.onLine) {
            return 'You are offline. Please check your internet connection and try again.'
        }

        if (! error.graphQLErrors?.[0]) {
            return error.message
        }

        const graphQLError = error.graphQLErrors[0]

        // Try to get first field in extensions
        let firstError!: string
        if (graphQLError.extensions?.validation) {
            const [first]: any = Object.values(graphQLError.extensions.validation)
            firstError = first?.[0]
        }

        return firstError ?? graphQLError.extensions?.reason ?? graphQLError.message ?? error.message
    }

    public async showHttpError(
        message: string = 'Something went wrong. Please try again later.',
        suppressWhenOffline = true,
        duration = 2500,
    ): Promise<void> {
        if (! navigator.onLine && suppressWhenOffline || ! message || message === 'undefined') {
            return
        }

        const toast = await this.toastController.create({
            color: 'dark',
            message,
            duration,
        })

        await toast.present()
    }

    public async getFetchPolicy(cacheOptions?: CacheOptions, cacheKey?: string): Promise<FetchPolicy> {
        const ttl = cacheOptions?.cacheTtl ?? AppConfig.cache.ttl

        // If offline, always use cache
        if (! navigator.onLine) {
            return 'cache-only'
        }

        // In case of a force-refresh, send a new request and write new timestamp to storage
        if (cacheOptions?.clearCache) {
            await this.storageService.set(`timestamp.${cacheKey}`, `${Date.now() + ttl * 1000}`)
            return 'network-only'
        }

        // In case cache has expired, send a new request and write new timestamp to storage
        const cachedTimestamp = await this.storageService.get(`timestamp.${cacheKey}`)

        if (! cachedTimestamp || +cachedTimestamp < Date.now()) {
            await this.storageService.set(`timestamp.${cacheKey}`, `${Date.now() + ttl * 1000}`)
            return 'network-only'
        }

        return 'cache-first'
    }

    public async clearExpiredCacheTimestamps(): Promise<void> {
        const cacheTimestamps = await this.storageService.getGroup('timestamp')
        const itemsToRemove = cacheTimestamps.filter((cachedTimestamp) => +cachedTimestamp < Date.now())
        await Promise.all(itemsToRemove.map((cachedTimestamp) => this.storageService.remove(cachedTimestamp)))
    }

    public async invalidateCache(groupKey?: string): Promise<void> {
        // Clear all cache timestamps and Apollo cache
        await Promise.all([
            this.storageService.removeGroup(groupKey ? `timestamp.${groupKey}` : 'timestamp'),
            this.apollo.client.clearStore(),
        ])
    }

}
