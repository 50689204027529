import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'firstCase',
    pure: true,
})
export class FirstCasePipe implements PipeTransform {

    public transform(text: string): string {
        if (! text) {
            return ''
        }
        return text.substr(0, 1).toUpperCase() + text.substr(1).toLowerCase()
    }

}
