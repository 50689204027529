import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { News } from '@app-graphql'
import { NewsService } from '@app-services'

@Component({
    selector: 'app-contest-news-list-page',
    templateUrl: './contest-news-list.page.html',
    styleUrls: ['./contest-news-list.page.scss'],
})
export class ContestNewsListPage implements OnDestroy {

    public contestId: string | null = null
    public news: Partial<News>[] | null = null
    public loading = false

    private news$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly newsService: NewsService,
    ) {
        this.news$ = this.newsService.news$.subscribe((news: Partial<News>[] | null) => {
            this.news = news
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
                await this.loadNews()
            }
        })
    }

    public ngOnDestroy(): void {
        this.news$?.unsubscribe()
    }

    public async loadNews(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        this.loading = true

        if (this.contestId) {
            await this.newsService.getNews(this.contestId, { clearCache: !! e })
        }

        await e?.detail?.complete()
        this.loading = false
    }

}
