import { Component, Input } from '@angular/core'
import { MenuWebContent } from '@app-graphql'

@Component({
    selector: 'app-dynamic-menu',
    templateUrl: './dynamic-menu.component.html',
    styleUrls: ['./dynamic-menu.component.scss'],
})
export class DynamicMenuComponent {

    @Input()
    public dynamicMenuItems: Partial<MenuWebContent>[] | null = null

    @Input()
    public mode: 'accordion' | 'list' = 'accordion'

}
