import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import { TopCatch, TopCatchesQuery, TopCatchesQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class TopCatchesService {

    public topCatches$ = new Subject<Partial<TopCatch>[] | null>()

    private topCatches: Partial<TopCatch>[] | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly topCatchesQueryService: TopCatchesQueryService,
    ) {
    }

    public async getTopCatchesByContestId(
        contestId: string,
        limit: number,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<TopCatch>[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `topCatches.${contestId}`)
        const topCatches$ = this.topCatchesQueryService.fetch({ contestId, limit }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<TopCatchesQuery>) => {
                this.topCatches = result.data.topCatches.topCatches as Partial<TopCatch>[]
                this.topCatches$.next(this.topCatches)

                return this.topCatches
            }),
        )

        try {
            return await lastValueFrom(topCatches$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
