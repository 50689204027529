import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

import { GeneralData, User } from '@app-graphql'
import { GeneralService, UserService } from '@app-services'
import { IonRefresherCustomEvent } from '@ionic/core'
import { IonRefresher } from '@ionic/angular'

@Component({
    selector: 'app-home-index-page',
    templateUrl: './home-index.page.html',
    styleUrls: ['./home-index.page.scss'],
})
export class HomeIndexPage implements OnDestroy, OnInit {

    public user: Partial<User> | null = null
    public generalData: Partial<GeneralData> | null = null

    private generalData$: Subscription
    private user$: Subscription

    constructor(
        private readonly generalService: GeneralService,
        private readonly userService: UserService,
    ) {
        this.generalData$ = this.generalService.generalData$.subscribe((generalData: Partial<GeneralData> | null) => {
            this.generalData = generalData
        })

        this.user$ = this.userService.user$.subscribe((user: Partial<User> | null) => {
            this.user = user
        })
    }

    public async ngOnInit(): Promise<void> {
        await Promise.all([
            this.generalService.getGeneralData(),
            this.userService.getUser(),
        ])
    }

    public ngOnDestroy(): void {
        this.generalData$?.unsubscribe()
        this.user$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await Promise.all([
            this.generalService.getGeneralData({ clearCache: true }),
            this.userService.getUser({ clearCache: true }),
        ])
        await e?.detail?.complete()
    }

}
