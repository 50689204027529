import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular'

import {
    apolloClientServiceFactory,
    ApolloConfigService,
    AuthInterceptor,
    LocaleInterceptor,
    resolveApolloClientOptions,
} from '@app-services'

@NgModule({
    exports: [ApolloModule],
    providers: [
        ApolloConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: resolveApolloClientOptions,
            deps: [ApolloConfigService],
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: apolloClientServiceFactory,
            deps: [ApolloConfigService, APP_INITIALIZER],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor.withExcludedOperationNames([
                'forgotPassword',
                'login',
                'register',
                'resendEmailVerification',
                'resetPassword',
                'verifyEmail',
            ]),
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true,
        },
    ],
})
export class GraphQLModule {
}
