import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {

    @Input()
    public steps = 5

    @Input()
    public value = 0

}
