@if ((messageWasDismissed === false || ! enableDismissOption)
&& hasPermission === false) {
    <ion-card class="ion-color ion-color-secondary ion-margin">
        <ion-card-content>
            <p class="ion-margin-bottom-3">
                <ion-icon name="notifications" />
                Enable push notifications to receive updates on your device.
            </p>
            <div class="card-buttons">
                <ion-button class="ion-color ion-color-light"
                            size="small"
                            (click)="enableNotifications()"
                >
                    Enable notifications
                </ion-button>
                @if (enableDismissOption) {
                    <ion-button class="ion-color ion-color-light"
                                size="small"
                                (click)="dismiss()"
                    >
                        Don't ask again
                    </ion-button>
                }
            </div>
        </ion-card-content>
    </ion-card>
}
