import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher, MenuController } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { Contest, LeaderboardEntry, Team } from '@app-graphql'
import { CacheTtl, ContestService, LeaderboardService } from '@app-services'

@Component({
    selector: 'app-contest-leaderboard-entry-page',
    templateUrl: './contest-leaderboard-entry.page.html',
    styleUrls: ['./contest-leaderboard-entry.page.scss'],
})
export class ContestLeaderboardEntryPage implements OnDestroy {

    public isStandalone = false
    public contestId: string | null = null
    public teamId: string | null = null

    public contest: Partial<Contest> | null = null
    public team: Partial<Team> | null = null
    public leaderboardEntry: Partial<LeaderboardEntry> | null = null

    public loading = false

    private contest$: Subscription | null = null
    private team$: Subscription | null = null
    private leaderboardEntry$: Subscription | null = null

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly contestService: ContestService,
        private readonly leaderboardService: LeaderboardService,
        private readonly menuController: MenuController,
    ) {

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId && params.teamId) {
                this.contestId = params.contestId
                this.teamId = params.teamId
                await this.loadData()
            }
        })

        this.activatedRoute.url.subscribe(async (url) => {
            this.isStandalone = url[0]?.path === 'standalone-leaderboard'
            if (this.isStandalone) {
                const mainMenu = (await this.menuController.getMenus())?.[0]
                mainMenu.disabled = true
            }
        })

        this.contest$ = this.contestService.contest$.subscribe((contest: Partial<Contest> | null) => {
            this.contest = contest
        })

        this.team$ = this.contestService.team$.subscribe((team: Partial<Team> | null) => {
            this.team = team
        })

        this.leaderboardEntry$ = this.leaderboardService.leaderboardEntries$
            .subscribe((leaderboardEntries: Partial<LeaderboardEntry>[] | null) => {
                this.leaderboardEntry = leaderboardEntries
                    ?.find((leaderboardEntry) => leaderboardEntry?.team?.id === this.teamId) || null
            })
    }

    public ngOnDestroy(): void {
        this.contest$?.unsubscribe()
        this.team$?.unsubscribe()
        this.leaderboardEntry$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadData(true)
        await e?.detail?.complete()
    }

    public async loadData(clearCache = false): Promise<void> {

        this.loading = true

        await Promise.all([
            this.contestService.getContestById(this.contestId, { clearCache }),
            this.contestService.getTeamById(
                this.teamId,
                { clearCache, cacheTtl: CacheTtl.Minute * 5 },
            ),
            this.leaderboardService.getLeaderboardEntries(
                this.contestId,
                { clearCache, cacheTtl: CacheTtl.Minute * 5 },
            ),
        ])

        this.loading = false
    }

}
