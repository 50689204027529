import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'formatPhoneNumber',
    pure: true,
})
export class FormatPhoneNumberPipe implements PipeTransform {

    public transform(
        phoneNumber: string,
        international = false,
        pretty = true,
        internationalIncludePlusSign = true,
    ): string {
        if (phoneNumber === null || phoneNumber === undefined) {
            return ''
        }

        const isInternational = phoneNumber.startsWith('+') || phoneNumber.startsWith('00')

        let formattedPhoneNumber = phoneNumber
        let formatAsInternational = international
        let isLocal = false

        formattedPhoneNumber = formattedPhoneNumber.split('(0)').join('').replace(/\D/g, '')

        if (formattedPhoneNumber.startsWith('00')) {
            formattedPhoneNumber = formattedPhoneNumber.substring(2)
        }

        // From international
        if (isInternational) {
            if (! international && formattedPhoneNumber.startsWith('31')) {
                formattedPhoneNumber = `0${formattedPhoneNumber.substring(2)}`
                isLocal = true
            } else {
                formatAsInternational = true // Force international formatting if not an NL number
            }

            // From local
        } else if (formatAsInternational) {
            if (formattedPhoneNumber.startsWith('0')) {
                formattedPhoneNumber = `31${formattedPhoneNumber.substring(1)}`
            }
            isLocal = true
        } else {
            isLocal = true
        }

        // Format area code
        if (pretty) {
            const FIXED_AREA_CODES = [
                '6', '10', '13', '14', '15', '20', '23', '24', '30', '33', '35', '36', '38', '40', '43', '45', '46',
                '50', '53', '55', '58', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79',
            ]

            let areaCodeLength = (isLocal ? 3 : 2)

            for (let i = 0; i < FIXED_AREA_CODES.length; i++) {
                const formattedAreaCode = (formatAsInternational ? '31' : '0') + FIXED_AREA_CODES[i]
                if (formattedPhoneNumber.substring(0, formattedAreaCode.length) === formattedAreaCode) {
                    areaCodeLength = formattedAreaCode.length
                    break
                }
            }

            if (formattedPhoneNumber.length >= 10) {
                let parts = [
                    formattedPhoneNumber.substring(0, areaCodeLength),
                    formattedPhoneNumber.substring(areaCodeLength, areaCodeLength + 3),
                    formattedPhoneNumber.substring(areaCodeLength + 3, areaCodeLength + 5),
                ]

                if (isLocal) {
                    parts = [
                        ...parts,
                        formattedPhoneNumber.substring(areaCodeLength + 5),
                    ]
                } else {
                    parts = [
                        ...parts,
                        formattedPhoneNumber.substring(areaCodeLength + 5, areaCodeLength + 7),
                        formattedPhoneNumber.substring(areaCodeLength + 7),
                    ]
                }
                formattedPhoneNumber = parts.join(' ')
            }

        }

        return (formatAsInternational && internationalIncludePlusSign)
            ? (`+${formattedPhoneNumber}`)
            : formattedPhoneNumber
    }

}
