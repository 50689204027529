import { Component, Input } from '@angular/core'

import { Fish, FishStatusEnum, Team } from '@app-graphql'
import { PendingFish } from '@app-interfaces'

@Component({
    selector: 'app-contest-fish-item',
    templateUrl: './contest-fish-item.component.html',
    styleUrls: ['./contest-fish-item.component.scss'],
})
export class ContestFishItemComponent {

    @Input()
    public fish?: Partial<Fish> | null = null

    @Input()
    public pendingFish?: PendingFish | null = null

    @Input()
    public team?: Partial<Team> | null = null

    @Input()
    public isTopCatch?: boolean | null = null

    @Input()
    public showFishStatus?: boolean = false

    @Input()
    public showPhoto?: boolean = false

    public readonly FishStatusEnum = FishStatusEnum

}
