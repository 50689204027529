import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import {
    Message,
    MessageEntry,
    MessageQuery,
    MessageQueryService,
    MessagesQuery,
    MessagesQueryService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions, PaginationOptions } from '@app/services'

@Injectable({
    providedIn: 'root',
})
export class MessageService {

    public messages$ = new Subject<Partial<MessageEntry>[] | null>()
    public message$ = new Subject<Partial<MessageEntry> | null>()

    private messages: Partial<MessageEntry>[] | null = null
    private message: Partial<Message> | null = null

    public paginationOptions: PaginationOptions = {
        first: 100,
        page: 1,
    }

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly messagesQueryService: MessagesQueryService,
        private readonly messageQueryService: MessageQueryService,
    ) {
    }

    public async getMessages(
        paginationOptions: PaginationOptions = this.paginationOptions,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<MessageEntry>[]> {

        // Clear paginated cache
        if (cacheOptions.clearCache) {
            await this.apiHelperService.invalidateCache('messages')
        }

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `messages.${paginationOptions?.page}`,
        )
        const messages$ = this.messagesQueryService.fetch(paginationOptions, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MessagesQuery>) => {
                this.messages = result.data.messages.data as Partial<MessageEntry>[]
                this.messages$.next(this.messages)

                return this.messages
            }),
        )

        try {
            return await lastValueFrom(messages$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getMessageById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Message>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `message.item-${id}`)
        const message$ = this.messageQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MessageQuery>) => {
                this.message = result.data.message as Partial<Message>
                this.message$.next(this.message)

                return this.message
            }),
        )

        try {
            return await lastValueFrom(message$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
