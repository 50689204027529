import { Pipe, PipeTransform } from '@angular/core'
import { startOfToday } from 'date-fns'

@Pipe({
    name: 'dateFromString',
    pure: true,
})
export class DateFromStringPipe implements PipeTransform {

    public transform(value: 'now' | 'today' | number | string | Date | null): Date {
        if (value === 'now' || value === null) {
            return new Date()
        }
        if (value === 'today') {
            return startOfToday()
        }

        return new Date(value.toString())
    }

}
