import { NgModule } from '@angular/core'

import { VendorSharedModule } from '@app-modules/vendor-shared.module'
import { ModalsSharedModule } from '@app-modals/modals-shared.module'
import { ImageViewerModal } from '@app-modals/image/viewer/image-viewer.modal'

const imageModals = [
    ImageViewerModal,
]

@NgModule({
    imports: [
        ModalsSharedModule,
        VendorSharedModule,
    ],
    declarations: [
        ...imageModals,
    ],
    exports: [
        ...imageModals,
    ],
})
export class ImageModalsModule {
}
