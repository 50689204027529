import { Component, OnDestroy } from '@angular/core'
import { InfiniteScrollCustomEvent, IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { MessageEntry, User } from '@app-graphql'
import { MessageService, PaginationOptions, UserService } from '@app-services'

@Component({
    selector: 'app-general-messages-list-page',
    templateUrl: './general-messages-list.page.html',
    styleUrls: ['./general-messages-list.page.scss'],
})
export class GeneralMessagesListPage implements OnDestroy {

    public user: Partial<User> | null = null
    public messages: Partial<MessageEntry>[] | null = null
    public loading = false

    public paginationOptions: PaginationOptions = {
        first: 15,
        page: 1,
    }
    public hasReachedEnd = false

    private user$: Subscription
    private messages$: Subscription

    constructor(
        private readonly messageService: MessageService,
        private readonly userService: UserService,
    ) {
        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user
        })

        this.messages$ = this.messageService.messages$.subscribe((messages) => {
            this.messages = this.paginationOptions.page === 1
                ? messages
                : [...this.messages, ...messages]

            // Ignore duplicates
            this.messages = this.messages.filter((message, index, self) =>
                index === self.findIndex((t) => (
                    t.id === message.id
                )),
            )

            this.hasReachedEnd = messages.length < this.paginationOptions.first
        })
    }

    // noinspection JSUnusedGlobalSymbols
    public async ionViewWillEnter(): Promise<void> {
        await this.loadData()
    }

    public async ngOnDestroy(): Promise<void> {
        this.user$?.unsubscribe()
        this.messages$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        this.hasReachedEnd = false
        await this.loadData(true)
        await e?.detail?.complete()
    }

    public async loadData(clearCache = false): Promise<void> {
        this.loading = true

        await this.userService.getUser({ clearCache })
        try {
            await this.messageService.getMessages(this.paginationOptions, { clearCache })
        } catch (e) {
            console.warn('err')
            this.hasReachedEnd = true
        }

        this.loading = false
    }

    public async loadMore(e?: InfiniteScrollCustomEvent): Promise<void> {
        this.paginationOptions.page++
        await this.loadData()

        await e.target.complete()
    }

}
