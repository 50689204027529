import { NgModule } from '@angular/core'

import {
    FirstCasePipe,
    FormatPhoneNumberPipe,
    SafePipe,
    TruncatePipe,
    UrlFromBlobPipe,
    UserNamesPipe,
} from '@app-pipes/text'

const textPipes = [
    FirstCasePipe,
    FormatPhoneNumberPipe,
    SafePipe,
    TruncatePipe,
    UrlFromBlobPipe,
    UserNamesPipe,
]

@NgModule({
    declarations: [...textPipes],
    exports: [...textPipes],
    providers: [...textPipes],
})
export class TextPipesModule {
}
