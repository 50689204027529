import { Component, OnDestroy, ViewChild } from '@angular/core'
import { AlertController, IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'
import { omit } from 'ramda'

import { Fish, FishStatusEnum, Team, User } from '@app-graphql'
import { PendingFish } from '@app-interfaces'
import { FishService, FormHelperService, UserService } from '@app-services'

@Component({
    selector: 'app-account-fish-page',
    templateUrl: './account-fish.page.html',
    styleUrls: ['./account-fish.page.scss'],
})
export class AccountFishPage implements OnDestroy {

    @ViewChild('pendingFishOptionsPopover')
    public pendingFishOptionsPopover: HTMLIonPopoverElement | null = null

    public user: Partial<User> | null = null
    public team: Partial<Team> | null = null

    public pendingFishItems: PendingFish[] | null = null
    public pendingFishPhotos: string[] | null = null
    public selectedPendingFish: PendingFish | null = null
    public submittedFishItems: Partial<Fish>[] | null = null
    public submittedFishPhotos: string[] | null = null

    public loading = false
    public submitting = false
    public apiError: string | null = null
    public submittedFishStatusFilter: FishStatusEnum | null = null

    public readonly FishStatusEnum = FishStatusEnum

    private user$: Subscription | null = null
    private pendingFishItems$: Subscription

    constructor(
        private readonly alertController: AlertController,
        private readonly fishService: FishService,
        private readonly formHelperService: FormHelperService,
        private readonly userService: UserService,
    ) {
        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user
            this.team = user?.teams?.find((team) => team?.isActive) || null

            this.submittedFishItems = this.team?.fish || []
            this.submittedFishPhotos = this.submittedFishItems?.map((item) => item.photo || '') || []
        })
        this.pendingFishItems$ = this.fishService.pendingFishItems$.subscribe((pendingFishItems) => {
            this.pendingFishItems = pendingFishItems
            this.pendingFishPhotos = pendingFishItems?.map((item) => item.photoFileUri) || []
        })
    }

    // noinspection JSUnusedGlobalSymbols
    public async ionViewWillEnter(): Promise<void> {
        await this.loadFishItems()
    }

    // noinspection JSUnusedGlobalSymbols
    public ionViewWillLeave(): void {
        this.apiError = null
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
        this.pendingFishItems$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadFishItems(true)
        await e?.detail?.complete()
    }

    public async loadFishItems(clearCache = false): Promise<void> {
        this.loading = true

        await Promise.all([
            this.userService.getUser({ clearCache }),
            this.fishService.getPendingFishItems(),
        ])

        this.loading = false
    }

    public async submitAllPendingFish(): Promise<void> {
        this.apiError = null

        if (! this.pendingFishItems?.length) {
            const alert = await this.alertController.create({
                header: 'No fish added',
                message: 'You have not added any fish to submit.',
                buttons: ['Ok'],
            })
            await alert.present()
            return
        }

        for (const pendingFish of this.pendingFishItems) {
            if (this.apiError) {
                return
            }
            const result = await this.submitPendingFish(pendingFish)
            if (result) {
                await this.removePendingFish(pendingFish)
            }
        }

        // Refresh
        this.submittedFishItems = null
        await this.loadFishItems(true)
    }

    public async submitPendingFish(pendingFish: PendingFish): Promise<boolean> {
        this.apiError = null

        if (this.submitting) {
            return false
        }

        this.submitting = true
        pendingFish.uploading = true

        try {
            // Load photo image from file system
            const photo = await this.fishService.getPendingFishPhotoBlob(pendingFish)

            // Upload to API
            await this.fishService.registerFish({
                ...omit(['photoFileUri', 'uploading'], pendingFish),
                photo,
            })

            this.submitting = false
            pendingFish.uploading = false

        } catch (e: any) {
            this.submitting = false
            pendingFish.uploading = false
            this.apiError = e?.message || 'An error occurred while submitting the fish. Please try again.'
            await this.formHelperService.scrollFirstErrorIntoView()

            return false
        }

        return true
    }

    public async confirmRemovePendingFish(pendingFish: PendingFish): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Remove Fish',
            message: 'Are you sure you want to remove this fish?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Remove',
                    role: 'destructive',
                    handler: () => this.removePendingFish(pendingFish),
                },
            ],
        })
        await alert.present()
    }

    public async removePendingFish(pendingFish: PendingFish): Promise<void> {
        await this.fishService.removePendingFish(pendingFish, true)
    }

    public async showPendingFishOptions($event: MouseEvent, fish: PendingFish): Promise<void> {
        this.selectedPendingFish = fish
        $event.stopPropagation()
        await this.pendingFishOptionsPopover?.present($event)
    }

}
