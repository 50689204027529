import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import { WebContent, WebContentQuery, WebContentQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app/services'

@Injectable({
    providedIn: 'root',
})
export class WebContentService {

    public webContent$ = new Subject<Partial<WebContent> | null>()

    private webContent: Partial<WebContent> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly webContentQueryService: WebContentQueryService,
    ) {
    }

    public async getWebContentById(id: string, cacheOptions?: CacheOptions): Promise<Partial<WebContent>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `webContent.${id}`)
        const webContent$ = this.webContentQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<WebContentQuery>) => {
                this.webContent = result.data.webContent as Partial<WebContent>
                this.webContent$.next(this.webContent)

                return this.webContent
            }),
        )

        try {
            return await lastValueFrom(webContent$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
