@if (contest?.contestFish?.length) {
    <app-contest-fish [contestFish]="contest.contestFish"
                      (fishChanged)="showFishData($event)"
    />

    <app-contest-catches [catches]="team?.fish"
                         [contestFish]="currentContestFish"
                         [showPhoto]="isMyTeam"
    >
        <div slot="empty">No {{ currentContestFish.name }} caught (yet)</div>
    </app-contest-catches>

} @else {
    <app-empty>
        There are no fish in this contest
    </app-empty>
}

<hr class="ion-color ion-color-light" />

<app-rules-modal #rulesModal
                 [breakpoints]="[0, 0.5, 1]"
                 [contestId]="contest?.id"
                 [handle]="true"
                 [initialBreakpoint]="0.5"
/>
<ion-button (click)="rulesModal.present()"
            class="ion-margin-horizontal ion-margin-bottom-3 ion-color ion-color-light"
            size="small"
>
    View contest rules
    <ion-icon name="reader-outline" slot="end" />
</ion-button>
