import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { lastValueFrom, map, Subject } from 'rxjs'

import {
    Contest,
    ContestQuery,
    ContestQueryService,
    ContestRulesQuery,
    ContestRulesQueryService,
    Team,
    TeamQuery,
    TeamQueryService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class ContestService {

    public contest$ = new Subject<Partial<Contest> | null>()
    public rules$ = new Subject<string | null>()
    public team$ = new Subject<Partial<Team> | null>()

    private contest: Partial<Contest> | null = null
    private rules: string | null = null
    private team: Partial<Team> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly contestQueryService: ContestQueryService,
        private readonly contestRulesQueryService: ContestRulesQueryService,
        private readonly teamQueryService: TeamQueryService,
    ) {
    }

    public async getContestById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Contest>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `contest.${id}`)
        const contest$ = this.contestQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ContestQuery>) => {
                this.contest = result.data.contest as Partial<Contest>
                this.contest$.next(this.contest)

                return this.contest
            }),
        )

        try {
            return await lastValueFrom(contest$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getContestRules(contestId: string, cacheOptions?: CacheOptions): Promise<string> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `contest.rules.${contestId}`)
        const rules$ = this.contestRulesQueryService.fetch({ contestId }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ContestRulesQuery>) => {
                this.rules = result.data.contest.rules
                this.rules$.next(this.rules)

                return this.rules
            }),
        )

        try {
            return await lastValueFrom(rules$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

    public async getTeamById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Team>> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `contest.team.${id}`)
        const team$ = this.teamQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<TeamQuery>) => {
                this.team = result.data.team as Partial<Team>
                this.team$.next(this.team)

                return this.team
            }),
        )

        try {
            return await lastValueFrom(team$)
        } catch (e: any) {
            await this.apiHelperService.showHttpError()
            return null
        }
    }

}
