import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { SwiperComponent } from 'swiper/angular'
import { Subscription } from 'rxjs'

import { GeneralData, MenuSponsor } from '@app-graphql'
import { GeneralService } from '@app-services'

@Component({
    selector: 'app-sponsors-logo-swiper',
    templateUrl: './sponsors-logo-swiper.component.html',
    styleUrls: ['./sponsors-logo-swiper.component.scss'],
})
export class SponsorsLogoSwiperComponent implements OnDestroy, OnInit {

    @ViewChild('swiperComponent', { static: true })
    public sponsorsLogoSwiper: SwiperComponent | null = null

    @Input()
    public contestId: string | null = null

    public sponsors: Partial<MenuSponsor>[] | null = null

    private generalData$: Subscription | null = null

    constructor(
        private readonly generalService: GeneralService,
    ) {
        this.generalData$ = this.generalService.generalData$.subscribe((generalData: Partial<GeneralData> | null) => {
            this.sponsors = generalData?.sponsors as Partial<MenuSponsor>[]
            this.start()
        })
    }

    public async ngOnInit(): Promise<void> {
        await this.loadSponsors()
    }

    public ngOnDestroy(): void {
        this.generalData$?.unsubscribe()
    }

    public async loadSponsors(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.generalService.getGeneralData({ clearCache: !! e })
    }

    public start(): void {
        this.sponsorsLogoSwiper?.swiperRef?.autoplay?.start()
    }

}
