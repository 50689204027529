import { Component, Input, OnChanges, OnDestroy } from '@angular/core'

import { BaseModal } from '@app-modals/base.modal'
import { ContestService, StatusBarStyleService } from '@app-services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-rules-modal',
    templateUrl: './rules.modal.html',
    styleUrls: ['./rules.modal.scss'],
})
export class RulesModal extends BaseModal implements OnDestroy, OnChanges {

    @Input()
    public contestId: string

    public rules: string | null = null

    private rules$: Subscription

    constructor(
        protected readonly statusBarStyleService: StatusBarStyleService,
        private readonly contestService: ContestService,
    ) {
        super(statusBarStyleService)

        this.rules$ = this.contestService.rules$.subscribe((rules: string | null) => {
            this.rules = rules
        })
    }

    public async ngOnChanges(): Promise<void> {
        if (this.contestId) {
            await this.contestService.getContestRules(this.contestId)
        }
    }

    public ngOnDestroy(): void {
        this.rules$?.unsubscribe()
    }

}
