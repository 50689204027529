import { Component, Input } from '@angular/core'

import { LeaderboardEntry, RankStatusEnum } from '@app-graphql'

@Component({
    selector: 'app-contest-score-badge',
    templateUrl: './contest-score-badge.component.html',
    styleUrls: ['./contest-score-badge.component.scss'],
})
export class ContestScoreBadgeComponent {

    @Input()
    public leaderboardEntry: LeaderboardEntry

    public readonly RankStatusEnum = RankStatusEnum

}
