<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>{{ content?.title || '' }}</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }
    </ion-toolbar>

</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (content) {
        <div class="tablet-max-600 ion-padding">
            <h1>{{ content.title }}</h1>
            @if (content.createdAt) {
                <div class="ion-text-muted ion-font-size-1 ion-margin-bottom-4">
                    {{ content.createdAt | date : 'MMMM d, y - HH:mm' }}
                </div>
            }
            <div [innerHTML]="content.content | safe : 'html'" appExternalContent></div>
        </div>
    }

</ion-content>
