<ion-modal #modal
           [breakpoints]="breakpoints"
           [class.action-sheet-modal]="!! breakpoints"
           [handle]="handle"
           [initialBreakpoint]="initialBreakpoint"
           [trigger]="trigger"
>
    <ng-template>

        <ion-header>
            <ion-toolbar class="ion-color ion-color-dark">
                <ion-buttons slot="end">
                    <ion-button (click)="modal?.dismiss()"
                                class="ion-color ion-color-light"
                                fill="clear"
                    >
                        <ion-icon name="close" slot="icon-only" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-color ion-color-dark">
            <swiper [initialSlide]="initialSlide"
                    [pagination]="(
                        images!.length > 1
                            ? { enabled: true, dynamicBullets: images!.length > 10 }
                            : false
                    )"
                    [zoom]="true"
            >
                @for (image of images; track image) {
                    <ng-template [zoom]="true"
                                 swiperSlide
                    >
                        <img [src]="($any(image).src ? $any(image).src : image) | safe : 'resourceUrl'" alt="" />

                        @if ($any(image).caption) {
                            <div class="ion-text-muted ion-padding image-caption"
                                 [innerHTML]="$any(image).caption | safe : 'html'"
                            ></div>
                        }
                    </ng-template>
                }
            </swiper>
        </ion-content>

    </ng-template>
</ion-modal>
