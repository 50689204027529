<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button [defaultHref]="'/contest/' + contestId + '/news'" />
        </ion-buttons>

        <ion-title>News</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

    </ion-toolbar>

</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="loadNewsItem($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (newsItem) {
        <div class="ion-flex ion-h-100 tablet-max-600 ion-padding news-item">
            @if (newsItem.image) {
                <img [src]="newsItem.image" alt="" class="news-item__image" />
            }
            <h1>{{ newsItem.title }}</h1>
            <div class="ion-text-muted ion-font-size-1 ion-margin-bottom-4">
                {{ newsItem.createdAt | date : 'MMMM d, y - HH:mm' }}
            </div>
            <div [innerHTML]="newsItem.content | safe : 'html'" appExternalContent></div>
        </div>
    }

</ion-content>
