<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>My team</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon name="refresh-outline" slot="icon-only" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (team) {
        <app-contest-team-information [team]="team" />
    } @else if (! loading) {
        <app-empty icon="people-outline">
            You are not part of a team yet.
        </app-empty>
    }

    @if (leaderboardEntry) {
        <app-contest-team-leaderboard-results [leaderboardEntry]="leaderboardEntry" />
    }

    @if (contest) {
        <app-contest-team-fish [contest]="contest" [team]="team" [isMyTeam]="true" />
    } @else if (team && ! loading) {
        <app-empty icon="ribbon-outline" height="auto">
            There is no contest running at the moment.
        </app-empty>
    }

</ion-content>

@if (team && contest) {
    <ion-footer>
        <ion-toolbar class="ion-text-center">
            <ion-button class="ion-margin ion-color ion-color-primary"
                        expand="block"
                        routerLink="/account/fish"
                        size="large"
            >
                Manage caught fish
                <ion-icon name="arrow-forward-outline" slot="end" />
            </ion-button>
        </ion-toolbar>
    </ion-footer>
}
