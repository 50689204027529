import { Injectable } from '@angular/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Platform } from '@ionic/angular'
import { BehaviorSubject, lastValueFrom } from 'rxjs'

import { FishInput, RegisterFishMutation, RegisterFishMutationService } from '@app-graphql'
import { PendingFish } from '@app-interfaces'
import { ApiHelperService, StorageService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class FishService {

    public pendingFishItems$ = new BehaviorSubject<PendingFish[] | null>(null)

    private pendingFishItems: PendingFish[] = []

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly platform: Platform,
        private readonly registerFishMutationService: RegisterFishMutationService,
        private readonly storageService: StorageService,
    ) {
    }

    public async registerFish(input: FishInput): Promise<RegisterFishMutation> {
        try {
            const response = await lastValueFrom(
                this.registerFishMutationService.mutate(
                    { input },
                    { context: { useMultipart: true } },
                ),
            )
            return response.data as RegisterFishMutation
        } catch (e: any) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async getPendingFishItems(): Promise<PendingFish[]> {
        const pendingFishData = await this.storageService.get('pendingFishItems')
        this.pendingFishItems = JSON.parse(pendingFishData || '[]')
        this.pendingFishItems$.next(this.pendingFishItems)

        return this.pendingFishItems
    }

    public async getPendingFishPhotoBlob(pendingFish: PendingFish): Promise<Blob> {
        let base64Data

        if (this.platform.is('hybrid')) {
            try {
                const file = await Filesystem.readFile({
                    directory: Directory.Data,
                    path: `pending-fish/${pendingFish.uniqueId}.jpg`,
                })

                if (file.data instanceof Blob) {
                    return file.data
                }

                base64Data = file.data
            } catch (e: any) {
                base64Data = ''
            }
        } else {
            base64Data = pendingFish.photoFileUri.split(',')[1]
        }

        const binaryData = atob(base64Data)
        let n = binaryData.length
        let data = new Uint8Array(n)
        while (n--) {
            data[n] = binaryData.charCodeAt(n)
        }

        return new Blob([data], { type: 'image/jpeg' })
    }

    public async addPendingFish(pendingFish: PendingFish): Promise<void> {
        // Remove existing item first if it exists
        await this.removePendingFish(pendingFish)

        this.pendingFishItems.push(pendingFish)
        this.pendingFishItems$.next(this.pendingFishItems)
        await this.storageService.set('pendingFishItems', JSON.stringify(this.pendingFishItems))
    }

    public async removePendingFish(pendingFish: PendingFish, deleteFile = false): Promise<void> {

        // Delete the photo from the filesystem
        if (deleteFile) {
            try {
                await Filesystem.deleteFile({
                    directory: Directory.Data,
                    path: `pending-fish/${pendingFish.uniqueId}.jpg`,
                })
            } catch (e: any) {
                // Ignore errors. The file probably doesn't exist.
            }
        }

        this.pendingFishItems = this.pendingFishItems.filter((item) => item.uniqueId !== pendingFish.uniqueId)
        this.pendingFishItems$.next(this.pendingFishItems)
        await this.storageService.set('pendingFishItems', JSON.stringify(this.pendingFishItems))
    }

}
