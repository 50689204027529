import { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications'

export enum NotificationType {
    Message = 'message',
    FishStatus = 'fishStatus',
    News = 'news',
    Other = 'other',
}

export interface NotificationCustomData {
    type?: NotificationType
    cacheToClear?: string[]
    route?: string
    contestId?: string | number
    itemId?: string | number
}

export type NotificationWithCustomData = Omit<PushNotificationSchema, 'data'> & { data: NotificationCustomData }

export type ActionPerformedWithNotificationWithCustomData =
    Omit<ActionPerformed, 'notification'>
    & { notification: NotificationWithCustomData }
