@if (leaderboardEntry) {
    <ion-grid class="team-leaderboard-results">
        <ion-row>
            <ion-col class="ion-text-center">
                <div class="ion-font-size-5 ion-font-weight-bold">{{ leaderboardEntry.rank }}</div>
                <div>Overall ranking</div>
            </ion-col>
            <ion-col class="ion-text-center">
                <div class="ion-font-size-5 ion-font-weight-bold">{{ leaderboardEntry.points }}</div>
                <div>Overall points</div>
            </ion-col>
        </ion-row>
    </ion-grid>
} @else {
    <ng-content select="[slot=empty]" />
}
