import { Injectable } from '@angular/core'
import { Geolocation } from '@capacitor/geolocation'
import { AlertController } from '@ionic/angular'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import { PermissionState } from '@capacitor/core'

@Injectable({
    providedIn: 'root',
})
export class LocationHelperService {

    constructor(
        private readonly alertController: AlertController,
    ) {
    }

    public async getLocation(): Promise<GeolocationPosition> {
        let currentPosition: GeolocationPosition | null = null

        // Check if location access was explicitly denied
        let locationPermission: PermissionState
        try {
            // Either coarse location or precise location is required
            const { location, coarseLocation } = await Geolocation.checkPermissions()
            locationPermission = coarseLocation === 'granted' ? coarseLocation : location
        } catch (e: any) {
            locationPermission = 'denied'
        }

        if (locationPermission === 'denied') {
            await this.showLocationPermissionMessage()
            return null
        }

        // Get the current position
        try {
            currentPosition = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 30 * 1000, // 30 seconds
                maximumAge: 60 * 1000, // 1 minute
            })
        } catch (e: any) {
            currentPosition = null
        }

        if (currentPosition) {
            return currentPosition
        }

        // The user denied location access or the request timed out
        const alert = await this.alertController.create({
            header: 'Location Error',
            message: 'We were unable to get your current location. Please try again.',
            buttons: ['Ok'],
        })
        await alert.present()

        return null
    }

    public async openLocationPermissionSettings(): Promise<void> {
        await NativeSettings.open({
            optionAndroid: AndroidSettings.Location,
            optionIOS: IOSSettings.LocationServices,
        })
    }

    private async showLocationPermissionMessage(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Location',
            message: 'We need your location to verify the location of the fish. '
                + 'Please enable location services for this app and try again.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Allow...',
                    handler: async () => {

                        // Open location settings if location services are disabled or permission was explicitly denied
                        let currentState
                        try {
                            currentState = await Geolocation.checkPermissions()
                        } catch (e: any) {
                            currentState = { location: 'denied', coarseLocation: 'denied' }
                        }
                        if (currentState.coarseLocation === 'denied' && currentState.location === 'denied') {
                            await this.openLocationPermissionSettings()
                            return
                        }

                        // Otherwise, request the location permission within the app
                        await Geolocation.requestPermissions({
                            permissions: ['location', 'coarseLocation'],
                        })
                    },
                },
            ],
        })
        await alert.present()
    }

}
