<ion-modal #modal
           [breakpoints]="breakpoints"
           [class.action-sheet-modal]="!! breakpoints"
           [handle]="handle"
           [initialBreakpoint]="initialBreakpoint"
           [trigger]="trigger"
>
    <ng-template>

        <ion-header>
            <ion-toolbar>
                <ion-title>Rules</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="modal?.dismiss()"
                                class="ion-color ion-color-primary"
                                fill="clear"
                    >
                        <ion-icon name="close" slot="icon-only" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            @if (rules) {
                <div class="ion-padding" [innerHTML]="rules" appExternalContent></div>
            } @else {
                <div class="ion-flex-center ion-margin-top-5">
                    <ion-spinner />
                </div>
            }
        </ion-content>

    </ng-template>
</ion-modal>
