import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-modals/base.modal'

@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image-viewer.modal.html',
    styleUrls: ['./image-viewer.modal.scss'],
})
export class ImageViewerModal extends BaseModal {

    @Input()
    public images: string[] | { src: string, caption: string }[] = []

    @Input()
    public initialSlide = 0

}
