import { Component, Input, OnChanges } from '@angular/core'

import { ContestFish, Fish, FishStatusEnum } from '@app-graphql'

@Component({
    selector: 'app-contest-catches',
    templateUrl: './contest-catches.component.html',
    styleUrls: ['./contest-catches.component.scss'],
})
export class ContestCatchesComponent implements OnChanges {

    @Input()
    public catches: Partial<Fish>[] | null = null

    @Input()
    public contestFish: Partial<ContestFish> | null = null

    @Input()
    public showPhoto?: boolean = false

    public filteredCatches: Partial<Fish>[] | null = null
    public filteredCatchPhotos: { src: string, caption: string }[] | null = null

    public ngOnChanges(): void {
        if (this.contestFish) {
            this.filteredCatches = this.catches
                ?.filter((fish: Partial<Fish>) => (
                    fish?.contestFish.id === this.contestFish?.id
                    && fish?.state === FishStatusEnum.Approved
                )) || null
        } else {
            this.filteredCatches = this.catches
        }

        this.filteredCatchPhotos = this.filteredCatches
            ?.map((fish: Partial<Fish>) => ({ src: fish?.photo, caption: `${fish?.length} mm` }))
    }

}
