import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { WebContent } from '@app-graphql'
import { WebContentService } from '@app-services'

@Component({
    selector: 'app-general-dynamic-page',
    templateUrl: './general-dynamic-page.component.html',
    styleUrls: ['./general-dynamic-page.component.scss'],
})
export class GeneralDynamicPageComponent implements OnDestroy {

    public content: Partial<WebContent> | null = null
    public contentId: string | null = null
    public loading = false

    private content$: Subscription | null = null

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly webContentService: WebContentService,
    ) {
        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contentId) {
                this.contentId = params.contentId
                await this.loadContent()
            }
        })

        this.content$ = this.webContentService.webContent$.subscribe((content) => {
            this.content = content
        })
    }

    public ngOnDestroy(): void {
        this.content$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadContent(true)
        await e?.detail?.complete()
    }

    public async loadContent(clearCache = false): Promise<void> {
        this.loading = true
        await this.webContentService.getWebContentById(this.contentId, { clearCache })
        this.loading = false
    }

}
