import { NgModule } from '@angular/core'

import { DatePipesModule } from '@app-pipes/date/date-pipes.module'
import { NavigationDirectivesModule } from '@app-directives/navigation/navigation-directives.module'
import { TextDirectivesModule } from '@app-directives/text/text-directives.module'
import { TextPipesModule } from '@app-pipes/text/text-pipes.module'
import { UiDirectivesModule } from '@app-directives/ui/ui-directives.module'

@NgModule({
    exports: [
        DatePipesModule,
        NavigationDirectivesModule,
        TextDirectivesModule,
        TextPipesModule,
        UiDirectivesModule,
    ],
})
export class ModalsSharedModule {
}
