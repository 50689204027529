import { NgModule } from '@angular/core'

import { ExternalLinkDirective } from '@app-directives/navigation/external-link/external-link.directive'
import { ScrollToElementDirective } from '@app-directives'

const navigationDirectives = [
    ExternalLinkDirective,
    ScrollToElementDirective,
]

@NgModule({
    declarations: [
        ...navigationDirectives,
    ],
    exports: [
        ...navigationDirectives,
    ],
})
export class NavigationDirectivesModule {
}
