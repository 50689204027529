import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { FishStatusEnum, TopCatch } from '@app-graphql'
import { CacheTtl, TopCatchesService } from '@app-services'

const MAX_TOP_CATCHES = 10

@Component({
    selector: 'app-contest-top-catches-page',
    templateUrl: './contest-top-catches.page.html',
    styleUrls: ['./contest-top-catches.page.scss'],
})
export class ContestTopCatchesPage implements OnDestroy {

    public contestId: string | null = null
    public topCatches: Partial<TopCatch>[] | null = null

    public fishPhotos: { src: string, caption: string }[] | null = null
    public loading = false

    private topCatches$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly topCatchesService: TopCatchesService,
    ) {
        this.topCatches$ = this.topCatchesService.topCatches$
            .subscribe((topCatches: Partial<TopCatch>[] | null) => {
                this.topCatches = topCatches
                this.fishPhotos = this.topCatches?.map((item) => {
                    let caption = `<span>${item.fish?.length} mm by team '${item.team?.name}'</span>`
                    if (item.isTopCatch) {
                        caption += `<ion-badge slot="end" class="ion-color ion-color-secondary ion-margin-start">
                            Top catch
                        </ion-badge>`
                    }

                    return {
                        src: item.fish?.photo || '',
                        caption,
                    }
                }) || []
            })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
                await this.loadTopCatches()
            }
        })
    }

    public ngOnDestroy(): void {
        this.topCatches$?.unsubscribe()
    }

    public async refresh(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        await this.loadTopCatches(true)
        await e?.detail?.complete()
    }

    public async loadTopCatches(clearCache: boolean = false): Promise<void> {
        if (! this.contestId) {
            return
        }

        this.loading = true

        await this.topCatchesService.getTopCatchesByContestId(
            this.contestId,
            MAX_TOP_CATCHES,
            { clearCache, cacheTtl: CacheTtl.Minute * 5 },
        )

        this.loading = false
    }

    protected readonly FishStatusEnum = FishStatusEnum
}
