import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastController } from '@ionic/angular'

import { AuthService, FormHelperService, PushService, UserService } from '@app-services'

@Component({
    selector: 'app-auth-login-page',
    templateUrl: './auth-login.page.html',
    styleUrls: ['./auth-login.page.scss'],
})
export class AuthLoginPage implements OnInit {

    public form: FormGroup | null = null
    public loading = false
    public apiError: string | null = null

    private returnUrl: string | null = null

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly pushService: PushService,
        private readonly router: Router,
        private readonly toastController: ToastController,
        private readonly userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required]),
        })
        this.activatedRoute.queryParams.subscribe(async (params) => {
            if (params.email && ! this.form?.controls?.email?.value) {
                this.form?.controls?.email?.setValue(params.email)
            }

            if (params.logout) {
                await this.authService.logout(true)
            }

            if (params.returnUrl) {
                this.returnUrl = params.returnUrl
            }
        })
    }

    public async submit(): Promise<void> {
        this.apiError = null

        await this.formHelperService.hideKeyboard()

        if (this.loading) {
            return
        }

        if (! this.form?.valid) {
            await this.formHelperService.reportFormErrors(this.form!)
            return
        }

        this.loading = true

        // Login
        try {
            this.userService.userRequiresEmailVerification = false
            this.userService.initialized = false // To force a refresh of the user data

            await this.authService.login({
                email: this.form.controls.email.value,
                password: this.form.controls.password.value,
            })

            await this.continueToHome()

            // Get Firebase messaging token, but fail silently if it's not available
            try {
                await this.pushService.getToken()
            } catch (tokenError: any) {
                //
            }

            window.setTimeout(() => {
                this.form?.reset()
                this.loading = false
            }, 1000)

        } catch (e: any) {
            this.loading = false
            this.apiError = e?.message
            await this.formHelperService.scrollFirstErrorIntoView()
        }
    }

    private async continueToHome(): Promise<void> {
        const user = await this.userService.getUser({ clearCache: true })
        if (user) {
            const welcomeMessage = await this.toastController.create({
                color: 'dark',
                message: `Welcome, ${user.firstName}!`,
                duration: 3000,
            })
            await welcomeMessage.present()
        }

        await this.router.navigateByUrl(this.returnUrl || '/', { replaceUrl: true })
    }

}
