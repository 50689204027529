import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client/core'
import { AlertController } from '@ionic/angular'
import { BehaviorSubject, lastValueFrom, map } from 'rxjs'

import {
    MeQuery,
    MeQueryService,
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationService,
    User,
} from '@app-graphql'
import { ApiHelperService, AuthService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    public user$ = new BehaviorSubject<Partial<User | null>>(null)
    public initialized = false
    public userRequiresEmailVerification = false

    private user: Partial<User> | null = null

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly authService: AuthService,
        private readonly alertController: AlertController,
        private readonly meQueryService: MeQueryService,
        private readonly resendEmailVerificationMutationService: ResendEmailVerificationMutationService,
        private readonly router: Router,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        await this.getUser()

        this.initialized = true
    }

    public async getUser(cacheOptions?: CacheOptions): Promise<Partial<User> | null> {

        // Only get the user if we're authenticated
        const isAuthenticated: boolean = await this.authService.isAuthenticated()
        if (! isAuthenticated) {
            return null
        }

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'user.me')
        const user$ = this.meQueryService.fetch(undefined, { fetchPolicy }).pipe(
            map(async (result: ApolloQueryResult<MeQuery>) => {
                this.user = result.data.me as Partial<User> | null
                this.user$.next(this.user)

                if (this.user === null) {
                    await this.authService.logout()
                }

                return this.user
            }),
        )

        try {
            return await lastValueFrom(user$)
        } catch (e: any) {
            await this.handleError(e)
            return null
        }
    }

    public async resendEmailVerificationLink(showMessage = false): Promise<ResendEmailVerificationMutation> {
        try {
            const response = await lastValueFrom(
                this.resendEmailVerificationMutationService.mutate(),
            )

            if (showMessage) {
                const alert = await this.alertController.create({
                    message: '',
                    buttons: [
                        {
                            text: 'A new email has been sent to you with a link to verify your email address.',
                            handler: () => this.router.navigateByUrl('/auth/login'),
                        },
                    ],
                })
                await alert.present()
            }

            return response.data as ResendEmailVerificationMutation
        } catch (e: any) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    private async handleError(e: any): Promise<void> {
        // User email needs to be verified
        if (e.message.includes('verified') && ! (await this.alertController.getTop())) {
            this.userRequiresEmailVerification = true
            const alert = await this.alertController.create({
                backdropDismiss: false,
                message: 'Your email address has not been verified yet. Use the link you received by email to do this.',
                buttons: [
                    {
                        text: 'Send me a new link',
                        handler: () => this.resendEmailVerificationLink(true),
                    },
                    {
                        text: 'Ok',
                    },
                ],
            })
            await alert.present()

            // Other error: show a toast message
        } else {
            await this.apiHelperService.showHttpError()
        }
    }

}
