<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>

        <ion-title>Sponsor</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

    </ion-toolbar>

</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="loadSponsor($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (sponsor) {
        <ion-card class="ion-margin tablet-max-600 ion-color ion-color-light">
            @if (sponsor.logo) {
                <div class="ion-padding-4">
                    <img [src]="sponsor.logo" alt="" />
                </div>
            }
            <ion-card-content>
                <h3>{{ sponsor.name }}</h3>
                <div [innerHTML]="sponsor.text" appExternalContent class="ion-margin-vertical"></div>

                @if (sponsor.link) {
                    <ion-button [appExternalLink]="sponsor.link || null"
                                appExternalLinkTarget="_blank"
                                class="ion-color ion-color-primary"
                    >
                        View website...
                    </ion-button>
                }
            </ion-card-content>
        </ion-card>
    }

</ion-content>
