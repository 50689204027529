import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-video-background',
    templateUrl: './video-background.component.html',
    styleUrls: ['./video-background.component.scss'],
})
export class VideoBackgroundComponent {

    @Input()
    public src: string = '/assets/videos/home-background.mp4'

    @Input()
    public autoplay: boolean = ! window.matchMedia('(prefers-reduced-motion: reduce)')?.matches

    public ready = false

}
