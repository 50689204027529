import { Component, OnDestroy } from '@angular/core'
import { IonRefresher } from '@ionic/angular'
import { IonRefresherCustomEvent } from '@ionic/core'
import { Subscription } from 'rxjs'

import { Sponsor } from '@app-graphql'
import { ActivatedRoute } from '@angular/router'
import { SponsorService } from '@app-services'

@Component({
    selector: 'app-contest-sponsor-list-page',
    templateUrl: './contest-sponsor-list.page.html',
    styleUrls: ['./contest-sponsor-list.page.scss'],
})
export class ContestSponsorListPage implements OnDestroy {

    public contestId: string | null = null
    public sponsors: Partial<Sponsor>[] | null = null
    public loading = false

    private sponsors$: Subscription

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly sponsorService: SponsorService,
    ) {
        this.sponsors$ = this.sponsorService.sponsors$.subscribe((sponsors: Partial<Sponsor>[] | null) => {
            this.sponsors = sponsors
        })

        this.activatedRoute.params.subscribe(async (params) => {
            if (params.contestId) {
                this.contestId = params.contestId
                await this.loadSponsors()
            }
        })
    }

    public ngOnDestroy(): void {
        this.sponsors$?.unsubscribe()
    }

    public async loadSponsors(e?: IonRefresherCustomEvent<IonRefresher>): Promise<void> {
        this.loading = true

        if (this.contestId) {
            await this.sponsorService.getSponsors(this.contestId, { clearCache: !! e })
        }

        await e?.detail?.complete()
        this.loading = false
    }

}
